import React, { useEffect, useRef, useState } from "react";
import { FaSearch, FaLink } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton, TablePagination } from "@mui/material";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import FullPagePopup from "./fullPagePopup";
import { Modal } from "../../components";
const DomainsReport = () => {
  const [domainReport, setDomainReport] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });

  const getDomainList = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("/api/domains", payload);
      if (res.status === 200) {
        setDomainReport(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getDomainList({
      ...paginationModel,
      perPage: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getDomainList({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      getDomainList({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getDomainList({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getDomainList({
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getDomainList({
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
        <div className="flex items-center">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={
              domainReport?.count
                ? domainReport?.count
                : domainReport?.domainListData?.length || 0
            }
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: domainReport?.count
                  ? domainReport?.count
                  : domainReport?.domainListData?.length || 0,
              },
            ]}
          />
        </div>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 10, filterable: false },
    {
      headerName: "Domain",
      field: "d.domain",
      width: 220,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row["d.domain"]}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row["d.domain"]}
          </a>
        );
      },
    },
    {
      headerName: "Backlinks",
      field: "backlinks_count",
      flex: 1,
      minWidth: 100,
      renderCell: (params) =>
        params?.row.backlinks_count > 0 ? (
          <button onClick={() => openPopup(params?.row['b.id'], params.row["d.domain"])}><span
            className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3"
          >
            {params?.row.backlinks_count}
          </span></button>
        ) : (
          <span className="py-1 px-2 !rounded hover:cursor-pointer ml-3">0</span>
        ),
    },
    {
      headerName: "First Name",
      field: "d.fname",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Last Name",
      field: "d.lname",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Email",
      field: "d.email",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Phone",
      field: "d.phone",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Industry",
      field: "i.industry_name",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "State",
      field: "d.state",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "City",
      field: "d.city",
      flex: 1,
      minWidth: 100,
    }
  ];

  const dataRows = (domainReport?.domainListData || [])?.map(
    (record, index) => {
      const counter = index + 1;
      const records = record;
      const {
        "d.id": id,
        "d.domain": backlink_domain,
         backlinks_count,
        "d.fname": fname,
        "d.lname": lname,
        "d.email": email,
        "d.phone": phone,
        "i.industry_name": industry_name,
        "d.state": state,
        "d.city": city,
        "d.created_at": created_at,
      } = record;

      return {
        counter,
        records,
        "b.id": id,
        "d.domain": backlink_domain,
         backlinks_count,
        "d.fname": fname || "",
        "d.lname": lname,
        "d.email": email,
        "d.phone": phone,
        "i.industry_name": industry_name || "",
        "d.state": state || "",
        "d.city": city,
        "d.created_at": created_at,
      };
    }
  );

  const [showPopup, setShowPopup] = useState(false); // State to control the popup visibility
  const showPopupRef = useRef(null);
  const [selectBackLinks, setSelectBackLinks] = useState("");
  const [backlinksData, setBacklinksData] = useState([]);
  const openPopup = (id, domain) => {
    setIsLoading(true)
    setSelectBackLinks(domain)
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/linkbuilder_user/task_info/${id}`, {})
      .then((response) => {
        setBacklinksData(response?.data)
        setIsLoading(false);
        setShowPopup(true);
      })
      .catch((error) => {
        setIsLoading(false)
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const closePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Domains", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          totalItems={domainReport?.count}
          paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
        />
      </div>
      <Modal
            open={!showPopup}
            handleModal={closePopup}
            cancelButtonRef={showPopupRef}
            className="max-w-6xl"
            title={`Backlinks - ${selectBackLinks}`}
        >
      <FullPagePopup
        backlinksData={backlinksData}
      />
      </Modal>
    </div>
  );
};

export default DomainsReport;

import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import api from "../../services/api";
import MUIDataTable from "../../components/MUIGrid";
import Loading from "../../components/Loader/Loading";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaInfoCircle } from "react-icons/fa";
import { Tooltip, IconButton } from "@mui/material";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
import { useLocation } from "react-router-dom";
export default function BacklinkDetails() {
  const location = useLocation();
  const [backlinksData, setBacklinksData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      backlink_id: params.get("backlink_id"),
      task_status: params.get("task_status"),
    };
  };

  const { backlink_id, task_status } = getQueryParams();
  const get_backlinks = () => {
    setIsLoading(true);
    const payload = {
      backlink_id: backlink_id,
      task_status: task_status,
    };
    api
      .post(`/api/backlinks/backlink_details`, payload)
      .then((response) => {
        setBacklinksData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    get_backlinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 10, filterable: false },
    {
      field: "domain",
      column: "domain",
      headerName: "Domain",
      hideable: false,
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            className="text-black no-underline hover:underline cursor-poi"
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 180,
    },
    {
      field: "backlink_domain",
      column: "backlink_domain",
      headerName: "Backlink Domain",
      hideable: false,
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${params?.value}`}
            className="text-black no-underline hover:underline cursor-poi"
          >
            {params?.value}
          </a>
        );
      },
      minWidth: 150,
    },
    {
      field: "link_to_website",
      column: "link_to_website",
      headerName: "Backlink Live URL",
      hideable: false,
      renderCell: (params) => {
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${params?.value}`}
            className="text-black no-underline hover:underline cursor-poi"
          >
            {params?.value}
          </a>
        );
      },
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Backlink Type",
      field: "type",
      minWidth: 130,
    },
    {
      field: "task_status",
      headerName: "Task Status",
      column: "task_status",
      width: 120,
      renderCell: (params) => {
        return <span className="capitalize">{params?.value}</span>;
      },
    },
    {
      field: "note",
      headerName: "Note",
      column: "note",
      minWidth: 200,
      align: "left",
      filterable: false,
      renderCell: (params) => {
        return (
          <div className="w-full">
            <Tooltip title={params.value}>
              <IconButton size="small" style={{ marginLeft: 8 }}>
                <FaInfoCircle />
              </IconButton>
              {params.value.length > 20
                ? `${params.value.substring(0, 20)}...`
                : params.value}
            </Tooltip>
          </div>
        );
      },
    },
  ];

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3">
        <GridToolbarColumnsButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarDensitySelector
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
        />
        <GridToolbarFilterButton
          sx={{
            borderRadius: "6px",
            marginRight: "4px",
            border: "1px solid",
            borderColor: "#e8eaee",
            height: "26px",
            paddingLeft: 1,
            paddingRight: 1,
          }}
          ref={setFilterButtonEl}
        />
      </GridToolbarContainer>
    );
  }
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };
  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }
  return (
    <div className="px-4 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Backlink Domain Task Details", link: "" },
        ]}
      />
      <div className="bg-white my-3 border rounded">
        {isLoading && <Loading />}
        <MUIDataTable
          columnDefs={columnDefs}
          items={backlinksData?.map((data, index) => {
            const {
              backlink_domain,
              domain,
              backlink_type_id,
              type,
              task_status,
              link_to_website,
              note,
            } = data;
            return {
              records: data,
              counter: index + 1,
              backlink_domain,
              domain,
              backlink_type_id,
              type,
              task_status: addSpaceBeforeUpperCase(task_status),
              link_to_website:
                link_to_website && link_to_website !== "null"
                  ? link_to_website
                  : "N/A",
              note: note || "N/A",
            };
          })}
          totalItems={backlinksData?.length}
          searchText={searchText}
          setSearchText={setSearchText}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          paginationMode="client"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
        />
      </div>
    </div>
  );
}

import React, { useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { InputField, SelectBox } from "../../components";
import Loading from "../../components/Loader/Loading";

export default function DownloadDailyReport({ handleModal }) {
  const [dates, setDates] = useState({ fromDate: "", toDate: "", isRange: 0 });
  const [isLoading, setIsLoading] = useState(false);

  const saveBlobAsExcelFile = (blobData, filename = "report.xlsx") => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();
    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      let url = `/api/linkbuilder_user/linkbuilders_excel_report/?start_date=${dates.fromDate}&end_date=${dates.toDate}`;
      if (dates.isRange) {
        url += `&by_range=1`;
      }
      const res = await api.get(url, {
        responseType: "blob",
      });

      if (res.status === 200) {
        const blobData = res.data;
        saveBlobAsExcelFile(
          blobData,
          `linkbuilder_task_status_${dates.fromDate}_to_${dates.toDate}.xlsx`
        );
        toast.success("File downloaded successfully");
      } else {
        toast.error("File couldn't be Download");
      }
    } catch (err) {
      toast.error("File couldn't be Download");
      console.error("Error downloading the report:", err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isLoading && <Loading />}
      <div className="grid md:grid-cols-1 gap-4 w-full">
        <div className="mt-2">
          <label htmlFor="rangeBy" className="inputLabel">
            Range By:
          </label>
          <SelectBox
            placeholder={"Select Backlink"}
            onChange={(e) => {
              setDates((prev) => ({ ...prev, isRange: e.target.value }));
            }}
            name="Backlinks"
            valueKey="value"
            labelKey="label"
            options={[
              { value: 0, label: "Daily" },
              { value: 1, label: "Weekly" },
            ]}
            value={dates.isRange}
            height="h-[42px]"
          />
        </div>
        <div className="flex space-x-4">
          <InputField
            label="Start Date"
            name="from_date"
            placeholder="From Date"
            type="date"
            value={dates.fromDate}
            onChange={(e) => {
              setDates((prev) => ({ ...prev, fromDate: e.target.value }));
            }}
            required={true}
          />
          <InputField
            label="End Date"
            name="to_date"
            placeholder="To Date"
            type="date"
            value={dates.toDate}
            onChange={(e) => {
              setDates((prev) => ({ ...prev, toDate: e.target.value }));
            }}
            required={true}
          />
        </div>
      </div>

      <div className="flex items-center justify-end mt-5 space-x-2 text-white">
        <button
          onClick={handleModal}
          type="button"
          className="btnPrimary bg-red-500"
        >
          Cancel
        </button>

        {isLoading ? (
          <div className="bg-blue/10 flex items-center justify-center rounded-md py-2 w-48">
            <img src="/img/loading.gif" className="w-7" alt="" />
          </div>
        ) : (
          <button
            type="button"
            onClick={handleSubmit}
            disabled={!dates.fromDate && !dates.toDate}
            className="btnPrimary bg-green-600"
          >
            Download
          </button>
        )}
      </div>
    </div>
  );
}

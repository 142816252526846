import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loading from "../../../components/Loader/Loading";
import { useSelector } from "react-redux";
import URLModal from "../../../pages/TaskList/TaskDetail/URLModal";
import MoneyDomainCard from "./MoneyDomainCard";
import MoneyDomainHeader from "./MoneyDomainHeader";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function MoneyDomainReports() {
  const { user } = useSelector((state) => state.auth);
  const [taskList, setTasksList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [taskIds, setTaskIds] = useState([]);
  const [isUrlModal, setIsUrlModal] = useState(false);
  const [filters, setFilters] = useState({
    groupOp: "",
    rules: [],
  });
  const initalData = {
    task_status: location?.state?.task_status || queryParams.get('task_status'),
    backlink: location?.state?.backlink || queryParams.get('backlink'),
    domain_data: {
      domain: location?.state?.domain_data?.domain || queryParams.get('domain'),
      domainId: location?.state?.domain_data?.domainId || queryParams.get('domainId') || '',
    },
  };
  const [selectedPayload, setSelectedPayload] = React.useState(initalData);
  const { is_browser_active, is_browser_domain } = useSelector(
    (state) => state.auth
  );
  const [link_to_website, setLinkToWebsite] = useState("");
  const [isTaskStatus, setTaskStatus] = useState({});
  const [isStatusNote, setStatusNote] = useState("");
  const [errors, setErrors] = useState({});

  // Task Status Dropdown
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const toggleTaskStatusDropdown = (index) => {
    setOpenDropdownIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const fetchTasksList = ({ filters, domainId = null }) => {
    setIsLoading(true);
    api
      .post(
        `/api/linkbuilder_user/task_info/${
          domainId ? domainId : selectedPayload?.domain_data?.domainId
        }`,
        {
          filters,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setTasksList(response.data);
          const taskIds = response?.data?.map((item) => item.id);
          setTaskIds(taskIds);
          setIsLoading(false);
        } else {
          console.error(`Error: ${response.status}`);
          toast.error(response.status);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    let newFilters;
    if (
      selectedPayload?.task_status === "" ||
      selectedPayload?.task_status === undefined
    ) {
      newFilters = {
        groupOp: "",
        rules: [],
      };
    } else {
      newFilters = {
        groupOp: "AND",
        rules: [
          {
            field: "task_status",
            op: "eq",
            data: selectedPayload?.task_status,
          },
        ],
      };
      if (selectedPayload?.backlink) {
        newFilters.rules.push({
          field: "bl.backlink_type_id",
          op: "eq",
          data: selectedPayload?.backlink,
        });
      }
    }
    fetchTasksList({
      filters: newFilters,
    });
    setFilters(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeConvert = (timestamp) => {
    const timezoneName = dayjs.tz.guess();
    dayjs.tz.setDefault(timezoneName);
    const parsedTime = dayjs(timestamp, "YYYY-MM-DD HH:mm:ss");
    const newTime = parsedTime.add(5, "hour").add(30, "minute");
    const currentTime = dayjs(newTime).format("ddd, MMM D, YYYY h:mm A");
    return currentTime;
  };
  const navigate = useNavigate();
  const handleSelectedBatch = (item) => {
    navigate(`/task-list/${item?.id}`, {
      state: {
        domain_id: item.domain_id,
        backlink_id: item.backlink_id,
        batch_id: item.batch_id,
        task_status: item.task_status,
        ready_status_id: item.ready_status_id,
        userId: user?.user?.id,
        filter: selectedPayload?.task_status,
        taskIds: taskIds,
        selectedPayload: selectedPayload,
      },
    });
  };
  const updateTask = async (taskId, task_status, prevStatus = null) => {
    setIsLoading(true);
    const taskErrors = {};
    if (prevStatus === "inReview" && task_status === "pending") {
      if (isStatusNote.trim() === "") {
        taskErrors.note_status = "Please enter a note.";
      } else {
        setErrors(taskErrors);
      }
      if (Object.keys(taskErrors).length > 0) {
        await setErrors(taskErrors);
        setIsLoading(false);
        return;
      }
    }
    const requestData = {
      task_status,
      ...(link_to_website && { link_to_website }),
      ...(isStatusNote && { note: isStatusNote }),
    };
    try {
      const res = await api.put(
        `/api/linkbuilder_user/update_task_status/${taskId}`,
        requestData
      );
      if (res.status === 200) {
        if (isUrlModal) {
          setIsUrlModal(false);
          setLinkToWebsite("");
          setStatusNote("");
        }
        fetchTasksList({
          filters: filters,
        });
        toast.success("Task status updated");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
    }
  };
  const handleSearch = () => {
    let newFilters = {
      groupOp: "AND",
      rules: [],
    };
    if (selectedPayload?.domain_data?.domain) {
      if (selectedPayload?.task_status) {
        newFilters.rules.push({
          field: "task_status",
          op: "eq",
          data: selectedPayload?.task_status,
        });
      }
      if (selectedPayload?.backlink) {
        newFilters.rules.push({
          field: "bl.backlink_type_id",
          op: "eq",
          data: selectedPayload?.backlink,
        });
      }
    }
    fetchTasksList({
      filters: newFilters,
    });
    setFilters(newFilters);
  };
  return (
    <div className="px-6 pt-4 relative">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Money Domain Report", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <MoneyDomainHeader
        isLoading={isLoading}
        selectedPayload={selectedPayload}
        setSelectedPayload={setSelectedPayload}
        fetchTasksList={fetchTasksList}
        setFilters={setFilters}
        setTasksList={setTasksList}
        filters={filters}
        handleSearch={handleSearch}
        user={user}
      />
      <div className="bg-white my-2 border rounded">
        <MoneyDomainCard
          taskList={taskList}
          handleSelectedBatch={handleSelectedBatch}
          is_browser_active={is_browser_active}
          is_browser_domain={is_browser_domain}
          timeConvert={timeConvert}
          toggleTaskStatusDropdown={toggleTaskStatusDropdown}
          openDropdownIndex={openDropdownIndex}
          updateTask={updateTask}
          setIsUrlModal={setIsUrlModal}
          setTaskStatus={setTaskStatus}
          user={user}
          setErrors={setErrors}
          selectedPayload={selectedPayload}
        />
      </div>
      {isUrlModal ? (
        <URLModal
          link_to_website={link_to_website}
          setLinkToWebsite={setLinkToWebsite}
          taskObj={isTaskStatus}
          onClose={() => {
            setTaskStatus({});
            setIsUrlModal(false);
            setStatusNote("");
            setLinkToWebsite("");
          }}
          updateStatus={updateTask}
          setStatusNote={setStatusNote}
          isStatusNote={isStatusNote}
          errors={errors}
        />
      ) : null}
    </div>
  );
}

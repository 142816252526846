import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import Loading from "../../../components/Loader/Loading";
import MUIDataTable from "../../../components/MUIGrid";
import { FaSearch } from "react-icons/fa";
import dayjs from "dayjs";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";

export default function DailyTasks() {
  const [isRecords, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("");
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });

  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [{ field: "bdb.task_status", op: "eq", data: "inReview" }],
  });

  const fetchListing = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const response = await api.post(
        `${process.env.REACT_APP_PUBLIC_API}/api/report/daily_tasks`,
        payload
      );
      setRecords(response.data);
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
      date: selectedDate,
    });
    get_user();
  }, []);

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="mb-3 mt-3 flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />

          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
      </GridToolbarContainer>
    );
  }

  const onSelectAll = (checked) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = isRecords?.tasks?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const isSelected = (item) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(
          ({ "bdb.id": backlinkDomain }) =>
            backlinkDomain && backlinkDomain === item["bdb.id"]
        ).length > 0
      ) {
        return true;
      }
    }
    return false;
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            ({ "bdb.id": backlinkDomain }) =>
              backlinkDomain && backlinkDomain !== data["bdb.id"]
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const checkboxColumn = [
    {
      field: "checkbox",
      column: "",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={selectedData?.length === isRecords?.tasks?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 10,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoading ? (
            <Loading />
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      disableColumnMenu: true,
      hideable: false,
    },
  ];

  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }
  const columnDefs = [
    ...checkboxColumn,
    { headerName: "Sr#", field: "counter", width: 80, filterable: false },
    {
      headerName: "Task Status",
      field: "bdb.task_status",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return (
          <>{addSpaceBeforeUpperCase(params?.row?.records?.task_status)}</>
        );
      },
      type: "singleSelect",
      disableColumnMenu: true,
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        status: value?.value,
      }),
      valueOptions: [
        { value: "inReview", label: "In Review" },
        { value: "hold", label: "Hold" },
      ],
    },
    {
      headerName: "Backlink Domain",
      field: "backlink_domain",
      flex: 1,
      minWidth: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row.backlink_domain}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row.backlink_domain}
          </a>
        );
      },
    },
    {
      headerName: "Domain",
      field: "domain",
      flex: 1,
      minWidth: 150,
      filterable: false,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row.domain}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row.domain}
          </a>
        );
      },
    },
  ];

  const dataRows = (isRecords?.tasks || [])?.map((record, index) => {
    const counter = index + 1;
    const records = record;
    const { "bdb.id": bid, task_status, backlink_domain, domain } = record;

    return {
      counter,
      records,
      "bdb.id": bid,
      task_status,
      backlink_domain,
      domain,
    };
  });

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
  };

  const get_user = async () => {
    const filters = {
      groupOp: "AND",
      rules: [
        { field: "is_active", op: "eq", data: 1 },
        { field: "role_id", op: "isAnyOf", data: [3, 8] },
      ],
    };
    try {
      const res = await api.post("/api/users/users_report", {
        filters,
        page: 1,
        perPage: 1000,
      });
      if (res.status === 200) {
        setUsers(res.data?.records);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const assignToUser = async () => {
    setIsLoading(true);
    const payload = {
      task_ids: selectedData?.map((item) => item["bdb.id"]) || [],
      user_id: user,
    };

    try {
      const res = await api.post("/api/report/assign_daily_tasks", payload);
      if (res.status === 200 || res.status === 201) {
        setSelectedData([]);
        fetchListing({
          filters: queryOptions,
          page: 1,
          perPage: paginationModel.pageSize,
          date: selectedDate,
        });
        toast.success("Domain has been successfully assigned to the user");
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "An unexpected error occurred"
      );
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
      date: selectedDate,
    });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      fetchListing({
        page: 1,
        size: paginationModel.pageSize,
        date: selectedDate,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    fetchListing({
      filters: queryOptions,
      page: 1,
      perPage: paginationModel.pageSize,
      date: selectedDate,
    });
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Daily Tasks", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-2 border rounded">
        <div className="flex justify-between items-center px-4 py-2">
          <div className="flex items-center">
            <input
              className="inputField disabled:cursor-not-allowed w-fit py-2 ml-2 h-[38px]"
              placeholder="Date"
              onChange={(e) => {
                setSelectedDate(e.target.value);
              }}
              name="DATE(bdb.assigned_date)"
              value={selectedDate}
              type="date"
            />
            <button className="btnPrimary ml-2" onClick={handleSearch}>
              Search
            </button>
          </div>

          <div className="flex items-center">
            <select
              onChange={(e) => setUser(e.target.value)}
              value={user}
              name="user_id"
              className="inputField w-[280px] h-[40px] text-black p-2 dark:bg-transparent dark:border-white/30 dark:text-white cursor-pointer rounded-md"
            >
              <option value="" className="dark:bg-blue">
                Select User
              </option>
              {users.map((item, index) => (
                <option key={index} value={item?.id} className="dark:bg-blue">
                  {item?.name.replace(/\b\w/g, (c) => c.toUpperCase())}
                  {item?.role ? `( ${item?.role} )` : ""}{" "}
                </option>
              ))}
            </select>
            <button
              onClick={() => assignToUser()}
              className={`btnPrimary ml-2 ${
                !selectedData.length
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-blue-500"
              }`}
              disabled={!selectedData.length}
            >
              Assign Task
            </button>
          </div>
        </div>
      </div>

      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={dataRows}
          CustomToolbar={CustomToolbar}
          height="75vh"
          searchable="No"
          showCount="No"
          pagination="Yes"
          paginationMode="client"
          totalItems={isRecords?.count}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          paginationModel={paginationModel}
          onFilterModelChange={onFilterChange}
        />
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import dayjs from "dayjs";
import { Status, Modal } from "../../../components";
import { IconButton, TablePagination } from "@mui/material";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { toast } from "react-toastify";
import { Badge } from "../../../components";
import api from "../../../services/api";
import Loading from "../../../components/Loader/Loading";
import { BsBrowserChrome } from "react-icons/bs";
import { ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { osName } from "react-device-detect";
import { useDispatch } from "react-redux";
import { FaGear } from "react-icons/fa6";
import {
  setIsBrowserActive,
  setIsBrowserDomain,
  setLoggedInUser,
} from "../../../store/slices/authSlice";
import axios from "axios";
const TaskListCard = ({
  expandedRows,
  handleExpandClick,
  handleSelectedBatch,
  is_browser_active,
  is_browser_domain,
  timeConvert,
  toggleTaskStatusDropdown,
  openDropdownIndex,
  updateTask,
  setIsUrlModal,
  setTaskStatus,
  setErrors,
  selectedPayload,
  backlinksStatusList,
  fetchFilterData,
  totalRecords,
  setSelectedData,
  selectedData,
  totalChildTasks,
  handleTopPageChange,
  handleTopPageSizeChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const [backlinkSelected, setBacklinkSelected] = useState({});
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const [taskItem, setTaskItem] = useState("");
  const handleModal = () => {
    setOpen(!open);
  };
  const [isChecked, setIsChecked] = useState(false);
  const [backlinkPayload, setBacklinkPayload] = useState({
    good_to_assign: backlinkSelected["bl.good_to_assign"],
    ready_status_id: backlinkSelected["bl.ready_status_id"] || "",
  });

  useEffect(() => {
    setIsChecked(backlinkSelected && backlinkSelected["bl.good_to_assign"]);
    setBacklinkPayload({
      good_to_assign: backlinkSelected["bl.good_to_assign"],
      ready_status_id: backlinkSelected["bl.ready_status_id"] || "",
    });
  }, [backlinkSelected]);

  const totalTasksList = totalRecords.find(
    (item) => item["bl.id"] === selectedPayload?.open_backlinkid
  );
  let itemId = totalTasksList?.["bl.id"];

  // Activity Dropdown
  const [showActivityList, setShowActivityList] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [loadingActivity, setLoadingActivity] = useState(null);
  const fetchActivity = (taskId, index) => {
    setIsLoading(true);
    //setLoadingActivity(index);
    api
      .get(`/api/linkbuilder_user/task_activity/${taskId}`)
      .then((response) => {
        setActivityList(response.data);
        if (response.data?.task_activity === null) {
          toast.error("No Task Activity");
          setShowActivityList(null);
          setLoadingActivity(false);
        } else {
          setShowActivityList((prevIndex) =>
            prevIndex === index ? null : index
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const dropdownRef = useRef(null);
  const activityRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activityRef.current && !activityRef.current.contains(event.target)) {
        setShowActivityList(null); // Close the activity list
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleTaskStatusDropdown(null); // Close the dropdown
      }
    };

    if (showActivityList === taskItem || openDropdownIndex === taskItem) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    showActivityList,
    openDropdownIndex,
    taskItem,
    setShowActivityList,
    toggleTaskStatusDropdown,
  ]);

  const renderDetailItem = (label, value, className, link) => {
    if (link) {
      return (
        <p className={className}>
          <b className="text-gray-600 dark:text-white">{label}:</b>
          <a
            href={`${value}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <span className="ml-1 text-sm text-gray-500">{value}</span>
          </a>
        </p>
      );
    } else {
      return (
        <p className={`${className} pb-2 flex items-baseline`}>
          <b className="text-gray-600 dark:text-white">{label}:</b>
          <span className="ml-1 text-sm text-gray-500">{value}</span>
        </p>
      );
    }
  };
  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }

  const handleInreviewToPending = (e, prevStatus, itemId) => {
    e.preventDefault();
    if (prevStatus === "inReview") {
      setIsUrlModal(true);
      setTaskStatus({
        rowId: itemId,
        status: "pending",
        prevStatus: prevStatus,
      });
    } else {
      updateTask(itemId, "pending", prevStatus);
    }
  };

  /*   const calculateTotalTasks = (items) => {
    if (!items || !Array.isArray(items) || items.length === 0) {
      return 0;
    }
    return items.reduce((total, item) => {
      const parsedItem = item;
      return total + parsedItem.total_tasks;
    }, 0);
  };
  const totalTasks = calculateTotalTasks(totalTasksList?.items); */

  const handleCopyContent = (content, message) => {
    // Copy the content to the clipboard
    if (!content) {
      toast.error(`no record`, { autoClose: 2000 });
      return;
    }
    navigator.clipboard.writeText(content);
    toast.success(`${message} copied to clipboard!`, { autoClose: 2000 });
  };
  const handleStartBrowser = async ({ domain, id, state_level = 0 }) => {
    setIsLoading(true);
    let profileId = null;
    try {
      const launchPayload = {
        os_type: osName === "Mac OS" ? "macos" : "windows",
        domain,
      };

      if (state_level === 1 || state_level === true) {
        launchPayload.state_level = state_level;
      }

      const launch = await api.post(
        "/api/multilogin/launch_browser_static_ip",
        launchPayload
      );

      if (launch.status === 200) {
        dispatch(setIsBrowserActive(1));
        dispatch(setIsBrowserDomain(domain));
        profileId = launch?.data?.profile_id;

        // Define a function to handle the URL launch
        const attemptUrlLaunch = async () => {
          const urlLaunch = await axios.get(launch.data.launchUrl, {
            headers: {
              Authorization: `Bearer ${launch.data.token}`,
              Accept: "application/json",
            },
            maxBodyLength: Infinity,
          });
          return urlLaunch;
        };

        let urlLaunch;
        try {
          urlLaunch = await attemptUrlLaunch();
        } catch (error) {
          if (
            error?.response?.data?.status?.error_code === "LOCK_PROFILE_ERROR"
          ) {
            // Unlock profile if needed

            await axios.get(
              "https://api.multilogin.com/bpds/profile/unlock_profiles",
              {
                headers: {
                  Authorization: `Bearer ${launch.data.token}`,
                  Accept: "application/json",
                },
              }
            );

            // Retry launching the URL after unlocking the profile
            urlLaunch = await attemptUrlLaunch();
          } else {
            throw error; // Rethrow error if it’s not a lock profile issue
          }
        }

        // Check response status and continue handling as in your original code
        if (urlLaunch.data.status.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              profile_id: profileId,
              is_browser_active: 1,
            }
          );
          if (update.status === 200) {
            toast.success("Browser started successfully");
          } else {
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
            toast.error(
              update?.data?.message || "Browser status couldn't be updated"
            );
          }
        } else {
          // Handle other errors as in your original code
          handleLaunchError(
            urlLaunch.data.status.error_code,
            domain,
            profileId
          );
        }
      } else {
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        toast.error(launch?.data?.message || "Browser couldn't be launched.");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setIsBrowserActive(null));
      dispatch(setIsBrowserDomain(null));
      handleApiError(error, domain, id, state_level);
    }
  };

  // Helper function to handle URL launch errors
  const handleLaunchError = (error, domain, profileId) => {
    if (error === "LOCK_PROFILE_ERROR") {
      console.log("👊 ~ handleStartBrowser ~ error:", error);
      // Handle other specific error codes here...
    }
  };

  // Helper function to handle API errors
  const handleApiError = (error, domain, id, state_level) => {
    if (error?.response?.data?.status?.error_code) {
      const errorMessage = error?.response?.data?.status?.error_code;
      if (
        errorMessage === "CORE_DOWNLOADING_STARTED" ||
        errorMessage === "CORE_DOWNLOADING_ALREADY_STARTED"
      ) {
        toast.error("Please try again after a minute", { autoClose: 10000 });
      } else if (
        errorMessage === "GET_PROXY_CONNECTION_IP_ERROR" &&
        state_level === 0
      ) {
        return handleStartBrowser({ domain, id, state_level: true });
      } else {
        toast.error(errorMessage || "Browser couldn't be launched.");
      }
    } else {
      toast.error("Proxy error: Could not proxy request!");
    }
    console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
  };

  const handleStopBrowser = async () => {
    setIsLoading(true);
    try {
      const launch = await api.post("/api/multilogin/stop_browser_static_ip");
      if (launch.status === 200) {
        const urlLaunch = await axios.get(launch.data.stop_url, {
          headers: { Authorization: `Bearer ${launch.data.token}` },
        });
        if (urlLaunch.data?.status?.http_code === 200) {
          const update = await api.post(
            "/api/multilogin/update_browser_status_static_ip",
            {
              is_browser_active: 0,
            }
          );
          if (update.status === 200) {
            toast.success("Browser stopped successfully");
            dispatch(setIsBrowserActive(null));
            dispatch(setIsBrowserDomain(null));
          } else {
            toast.error(
              update?.data?.message || "Browser status couldn't be updated"
            );
          }
        } else {
          toast.error(
            urlLaunch?.data?.message || "Browser couldn't be stopped"
          );
        }
      } else {
        toast.error(launch?.data?.message || "Browser couldn't be stopped");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      if (
        error?.response?.data?.status?.message === "profile already stopped"
      ) {
        toast.success("Browser stopped successfully");
        dispatch(setIsBrowserActive(null));
        dispatch(setIsBrowserDomain(null));
        return await api.post(
          "/api/multilogin/update_browser_status_static_ip",
          {
            is_browser_active: 0,
          }
        );
      }
      toast.error(
        error?.response?.data?.message || "Browser couldn't be stopped"
      );
      if (error?.response?.data?.message === "Browser not exists") {
        handleLogout();
      }
      console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
    }
  };

  const handleLogout = async () => {
    setIsLoading(true);
    try {
      await api.get(`${process.env.REACT_APP_PUBLIC_API}/api/logout`);
      localStorage.clear();
      dispatch(setLoggedInUser(null));
    } catch (err) {
      console.log("👊 ~ handleLogout ~ err:", err);
    }
    setIsLoading(false);
  };

  const handleUpdateBacklink = (item) => {
    setBacklinkSelected(item);
    handleModal();
  };

  const handleToggle = () => {
    setIsChecked((prev) => {
      const newValue = !prev;
      setBacklinkPayload((prevState) => ({
        ...prevState,
        good_to_assign: newValue ? true : false,
      }));

      return newValue;
    });
  };

  const updateBacklinkData = (e) => {
    setIsLoading(true);
    e.preventDefault();
    api
      .put(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/updateBacklink`, {
        id: backlinkSelected?.["bl.id"],
        backlink_domain: backlinkSelected?.backlink_domain,
        good_to_assign: backlinkPayload?.good_to_assign,
        ready_status_id: parseInt(backlinkPayload?.ready_status_id) || "",
      })
      .then(() => {
        toast.success("Backlink Updated", { autoClose: 3000 });
        fetchFilterData();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const totalTaskCount = totalTasksList?.childRows?.length;

  const onSelectAll = (checked) => {
    if (checked) {
      const bids = totalTasksList?.childRows
        ? totalTasksList?.childRows?.map((item) => item._id)
        : [];
      setSelectedData(bids);
    } else {
      setSelectedData([]);
    }
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          if (!prevSelectedData.includes(data._id)) {
            return [...prevSelectedData, data._id];
          }
          return prevSelectedData;
        });
      } else {
        setSelectedData((prevSelectedData) => {
          return prevSelectedData.filter((id) => id !== data._id);
        });
      }
    } catch (err) {
      console.error("Error in onSingleSelect:", err);
    }
  };

  const isSelected = (data) => {
    return selectedData.some((id) => id === data._id);
  };
console.log('expandedRows',expandedRows);
  return (
    <>
      {isLoading && <Loading />}
      <div
        className={` dark:bg-gray-800 last:border-none shadow-md shadow-black/20 dark:border-white/20"
      }`}
      >
        <div
          className={`grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 py-0 ${
            totalTasksList?.childRows ? "border-b " : ""
          }`}
        >
          <div className="flex items-center text-sm gap-3 flex-wrap justify-between ">
            {totalTasksList?.["bl.id"] ? (
              <IconButton
                onClick={() => {
                  handleExpandClick(itemId, "");
                }}
                sx={{
                  backgroundColor: "transparent",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  "&:active": {
                    backgroundColor: "#ffffff",
                  },
                }}
              >
                {expandedRows[itemId] ? (
                  <>
                    <input
                      type="checkbox"
                      onClick={(e) => {
                        e.stopPropagation();
                        onSelectAll(e.target.checked);
                      }}
                      className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-1`}
                      checked={
                        totalTaskCount &&
                        selectedData?.length === totalTaskCount
                      }
                      disabled={!totalTaskCount}
                    />
                    <MdKeyboardArrowDown size={22} />
                    <a
                      href={`https://www.${totalTasksList?.backlink_domain}`}
                      className="hover:text-secondary underline mx-1 text-sm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-lg font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        {totalTasksList?.backlink_domain}
                      </span>
                    </a>{" "}
                    {totalChildTasks?.totalTasks ? (
                      <Badge value={totalChildTasks?.totalTasks} />
                    ) : null}
                    <span
                      className="ml-1"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUpdateBacklink(totalTasksList);
                      }}
                    >
                      <FaGear size={20} className="text-bg-gray-600" />
                    </span>
                  </>
                ) : (
                  <>
                    <MdKeyboardArrowRight size={22} />
                    <a
                      href={`https://www.${totalTasksList.backlink_domain}`}
                      className="hover:text-secondary underline mx-1 text-sm"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        {totalTasksList?.backlink_domain}
                      </span>
                    </a>
                    {totalChildTasks?.totalTasks ? (
                      <Badge value={totalChildTasks?.totalTasks} />
                    ) : null}
                    <span className="ml-1">
                      <FaGear size={20} />
                    </span>
                  </>
                )}
              </IconButton>
            ) : null}
            <div className="flex w-fit gapx-2 items-center">
              <TablePagination
                color="primary"
                shape="rounded"
                size="medium"
                showFirstButton
                showLastButton
                count={
                  totalChildTasks?.totalTasks
                    ? totalChildTasks?.totalTasks
                    : totalRecords?.length || 0
                }
                page={selectedPayload.page - 1 || 0}
                onPageChange={(e, page) => handleTopPageChange(page)}
                rowsPerPage={selectedPayload.pageSize || 0}
                onRowsPerPageChange={(e) =>
                  handleTopPageSizeChange(e.target.value)
                }
                component="div"
                rowsPerPageOptions={[
                  25,
                  50,
                  75,
                  100,
                  250,
                  500,
                  1000,
                  1500,
                  2000,
                  {
                    label: "All",
                    value: totalChildTasks?.totalTasks
                      ? totalChildTasks?.totalTasks
                      : totalRecords?.length || 0,
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div
          className={`bg-white grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 py-0 ${
            totalTasksList?.childRows ? "border-b " : ""
          }`}
        >
          {totalTasksList?.childRows && totalTasksList.childRows.length > 0
            ? totalTasksList.childRows.map((item, index) => (
                <div
                  className={`overflow-hidden transition-max-height duration-500 ease-in-out px-2 max-h-screen py-3 overflow-x-auto border-b`}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 text-sm">
                    <div className="flex items-start">
                      <input
                        type="checkbox"
                        checked={isSelected(item)}
                        onChange={(e) =>
                          onSingleSelect({
                            checked: e.target.checked,
                            data: item,
                          })
                        }
                        className="form-checkbox h-4 w-4 text-primary-100 focus:ring-0 cursor-pointer mr-2"
                      />{" "}
                      *
                      <div>
                        <h2 className="text-base font-medium mb-1">
                          Money Site Info
                        </h2>
                        {renderDetailItem("City", item?.city)}
                        {renderDetailItem("Phone", item?.phone)}
                        {renderDetailItem("Domain", item?.domain)}
                      </div>
                    </div>
                    <div>
                      <h2 className="text-base font-medium mb-1">
                        Backlink URL Info
                      </h2>
                      {renderDetailItem(
                        "Live Status",
                        addSpaceBeforeUpperCase(item?.live_status)
                      )}
                      {renderDetailItem(
                        "Backlink Type",
                        addSpaceBeforeUpperCase(item?.backlink_type)
                      )}
                      {renderDetailItem(
                        "Backlink Domain",
                        addSpaceBeforeUpperCase(item?.backlink_name)
                      )}
                      <div className="flex items-center">
                        <p className="flex flex-wrap">
                          <b className="text-gray-600 dark:text-white">
                            Backlink Live URL:
                          </b>
                          {item?.link_to_website &&
                          item.link_to_website !== "null" ? (
                            <a
                              href={item.link_to_website}
                              className="hover:text-secondary underline"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="ml-1 text-sm text-gray-500 block w-[280px] truncate">
                                {item?.link_to_website}{" "}
                              </span>
                            </a>
                          ) : (
                            <span className="ml-2">N/A</span> // Added margin to match spacing
                          )}
                        </p>
                        <div className="w-[100px]">
                          <button
                            onClick={() =>
                              handleCopyContent(
                                item?.link_to_website,
                                "Backlink Live URL"
                              )
                            }
                            className="flex items-center px-2 py-2"
                          >
                            {item?.link_to_website &&
                            item?.link_to_website !== "null" ? (
                              <ClipboardDocumentIcon className="w-5 mt-4 cursor-pointer text-black hover:text-gray-600" />
                            ) : (
                              ""
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h2 className="text-base  font-medium mb-1">
                        Task Details
                      </h2>
                      {renderDetailItem(
                        "Assigned Date",
                        dayjs(item.assigned_date)?.format("ddd, MMM D, YYYY")
                      )}
                      {renderDetailItem("Assigned To", item?.user_name)}
                      {renderDetailItem("Batch Number", item?.batch_number)}
                      {renderDetailItem(
                        "Hold Reason",
                        item?.note ? item?.note : "N/A"
                      )}
                      <div className="mb-2">
                        <div className="flex items-center gap-1 mt-0">
                          <span className="font-bold text-gray-500 dark:text-white">
                            Activity:
                          </span>
                          <div
                            className="flex flex-col items-center relative"
                            ref={activityRef}
                          >
                            <button className="flex items-center gap-2">
                              Show Activity
                              {loadingActivity === item._id ? (
                                <img
                                  src="/img/loading.gif"
                                  className="w-4"
                                  alt=""
                                />
                              ) : (
                                <>
                                  {showActivityList === item._id ? (
                                    <IoClose
                                      onClick={() => setShowActivityList(null)}
                                      className="w-6 text-red-400"
                                      size="22"
                                    />
                                  ) : (
                                    <FaChevronDown
                                      onClick={() => {
                                        setTaskItem(item._id);
                                        fetchActivity(item._id, item._id);
                                      }}
                                      className="w-4 text-black font-semibold"
                                    />
                                  )}
                                </>
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="relative flex justify-between">
                          {showActivityList === item._id && (
                            <>
                              <div className="bg-white dark:bg-white/20 backdrop-blur-lg text-sm shadow-xl dark:shadow-black mt-1 p-3 rounded-md flex flex-col w-80  min-h-[100px] max-h-[150px] overflow-y-scroll border dark:border-white/20 ">
                                {activityList?.task_activity?.map(
                                  (item, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center justify-between border-b hover:bg-gray-100 dark:hover:bg-white/10 hover:rounded p-2"
                                    >
                                      <div className="capitalize">
                                        {" "}
                                        {addSpaceBeforeUpperCase(item.status)}
                                      </div>
                                      <div>{timeConvert(item.timestamp)}</div>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {renderDetailItem(
                        "Updated On",
                        item.updated_at
                          ? dayjs(item.updated_at)?.format("ddd, MMM D, YYYY")
                          : "N/A"
                      )}
                    </div>
                    <div>
                      <div className="grid grid-cols-3 gap-0">
                        <div></div>
                        <div className="col-span-2 mb-3 ml-0">
                          {+is_browser_active ? (
                            <button
                              onClick={handleStopBrowser}
                              className="capitalize cursor-pointer btnPrimary py-2 px-2 text-xs font-semibold w-[139px]  text-red-600  flex items-center bg-white border  rounded-md"
                            >
                              <BsBrowserChrome className="mr-1" size={18} />
                              stop browser
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                if (
                                  +is_browser_active &&
                                  +is_browser_active === 1 &&
                                  is_browser_domain !== item.domain
                                )
                                  return toast.error(
                                    "Please stop the previous browser"
                                  );
                                handleStartBrowser({
                                  domain: item?.domain,
                                  id: item.id,
                                });
                              }}
                              className="capitalize cursor-pointer btnPrimary py-2 px-2 text-xs rounded-full font-semibold w-[139px] text-[#11223c] bg-gray-300 border"
                              disabled={
                                !!+is_browser_active
                                // &&
                                // (!item?.city || is_browser_domain !== item.domain)
                              }
                            >
                              <BsBrowserChrome className="mr-1" size={18} />
                              start browser
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-0">
                        <div></div>
                        <div className="col-span-2 mb-3 ml-0">
                          <button
                            className="btnPrimary py-2 px-2 text-xs rounded-full capitalize text-[#11223c] font-semibold w-[139px]"
                            disabled={!!+is_browser_active}
                            onClick={() => {
                              // if (
                              //   +is_browser_active &&
                              //   +is_browser_active === 1 &&
                              //   is_browser_domain !== item.domain
                              // )
                              //   return toast.error(
                              //     "Please stop the previous browser"
                              //   );
                              handleSelectedBatch(item);
                            }}
                          >
                            Start Task
                          </button>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-0">
                        <span className="font-bold text-gray-600 dark:text-white mt-2">
                          Task Status:
                        </span>
                        <Status type={item.task_status} />
                        <div className="flex items-center gap-2">
                          <button
                            onClick={() => {
                              setTaskItem(item._id);
                              toggleTaskStatusDropdown(item.id);
                            }}
                            className="absolute"
                          >
                            {openDropdownIndex === item.id ? (
                              <IoClose className="w-6 text-red-400" size={22} />
                            ) : (
                              <FaChevronDown className="w-4 text-gray-500" />
                            )}
                            <div
                              className="relative flex justify-end"
                              ref={dropdownRef}
                            >
                              {openDropdownIndex === item.id && (
                                <div className="bg-white dark:bg-dark dark:shadow-black shadow-md absolute top-full left-1/2 transform -translate-x-1/2 text-left p-2 mt-1 rounded-md flex flex-col w-32 z-50">
                                  {item.task_status === "hold" ? (
                                    <>
                                      <button
                                        onClick={() => {
                                          setIsUrlModal(true);
                                          setErrors({});
                                          setTaskStatus({
                                            rowId: item.id,
                                            status: "cancelled",
                                          });
                                        }}
                                        className={`py-2 px-4 hover:bg-gray-50 text-red-600 dark:text-gray-300 rounded ${
                                          item.task_status === "cancelled" &&
                                          "bg-gray-100"
                                        }`}
                                      >
                                        Cancelled
                                      </button>
                                      <button
                                        onClick={() => {
                                          setIsUrlModal(true);
                                          setErrors({});
                                          setTaskStatus({
                                            rowId: item.id,
                                            status: "reOpen",
                                          });
                                        }}
                                        className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                          item.task_status === "reOpen" &&
                                          "bg-gray-50"
                                        }`}
                                      >
                                        Reopen
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(e) =>
                                          handleInreviewToPending(
                                            e,
                                            item.task_status,
                                            item.id
                                          )
                                        }
                                        className={`py-2 px-4 hover:bg-gray-50 text-red-600 dark:text-red-400 rounded ${
                                          item.task_status === "pending" &&
                                          "bg-red-100"
                                        }`}
                                      >
                                        Pending
                                      </button>
                                      <button
                                        onClick={() =>
                                          updateTask(item.id, "inProcess")
                                        }
                                        className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                          item.task_status === "inProcess" &&
                                          "bg-indigo-100"
                                        }`}
                                      >
                                        In Process
                                      </button>
                                      <button
                                        onClick={() =>
                                          updateTask(item.id, "inReview")
                                        }
                                        className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                          item.task_status === "inReview" &&
                                          "bg-indigo-100"
                                        }`}
                                      >
                                        In Review
                                      </button>
                                      <button
                                        onClick={() => {
                                          setIsUrlModal(true);
                                          setErrors({});
                                          setTaskStatus({
                                            rowId: item.id,
                                            status: "hold",
                                          });
                                        }}
                                        className={`py-2 px-4 hover:bg-gray-50 text-gray-600 dark:text-gray-300 rounded ${
                                          item.task_status === "hold" &&
                                          "bg-gray-100"
                                        }`}
                                      >
                                        Hold
                                      </button>
                                      <button
                                        onClick={() => {
                                          setIsUrlModal(true);
                                          setErrors({});
                                          setTaskStatus({
                                            rowId: item.id,
                                            status: "cancelled",
                                          });
                                        }}
                                        className={`py-2 px-4 hover:bg-gray-50 text-red-600 dark:text-gray-300 rounded ${
                                          item.task_status === "cancelled" &&
                                          "bg-gray-100"
                                        }`}
                                      >
                                        Cancelled
                                      </button>
                                      <button
                                        onClick={() =>
                                          updateTask(item.id, "completed")
                                        }
                                        className={`py-2 px-4 hover:bg-gray-50 text-green-600 dark:text-green-300 rounded ${
                                          item.task_status === "completed" &&
                                          "bg-gray-50"
                                        }`}
                                      >
                                        Completed
                                      </button>
                                      <button
                                        onClick={() => {
                                          setIsUrlModal(true);
                                          setErrors({});
                                          setTaskStatus({
                                            rowId: item.id,
                                            status: "reOpen",
                                          });
                                        }}
                                        className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                          item.task_status === "reOpen" &&
                                          "bg-gray-50"
                                        }`}
                                      >
                                        Reopen
                                      </button>
                                    </>
                                  )}
                                </div>
                              )}
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : null}
        </div>

        {totalTasksList?.childRows && totalTasksList.childRows.length > 0 ? (
          <div
            className={`grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 py-0 ${
              totalTasksList?.childRows ? "border-b " : ""
            }`}
          >
            <div className="flex items-center text-sm gap-3 flex-wrap justify-between">
              <div className="flex justify-end w-full">
                <TablePagination
                  color="primary"
                  shape="rounded"
                  size="medium"
                  showFirstButton
                  showLastButton
                  count={
                    totalChildTasks?.totalTasks
                      ? totalChildTasks?.totalTasks
                      : totalRecords?.length || 0
                  }
                  page={selectedPayload.page - 1 || 0}
                  onPageChange={(e, page) => handleTopPageChange(page)}
                  rowsPerPage={selectedPayload.pageSize || 0}
                  onRowsPerPageChange={(e) =>
                    handleTopPageSizeChange(e.target.value)
                  }
                  component="div"
                  rowsPerPageOptions={[
                    25,
                    50,
                    75,
                    100,
                    250,
                    500,
                    1000,
                    1500,
                    2000,
                    {
                      label: "All",
                      value: totalChildTasks?.totalTasks
                        ? totalChildTasks?.totalTasks
                        : totalRecords?.length || 0,
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Modal
        open={open}
        handleModal={handleModal}
        cancelButtonRef={cancelButtonRef}
        className="max-w-2xl"
        title="Update Backlink"
        handleModalSubmit={updateBacklinkData}
        modalType="new"
      >
        <div className="grid md:grid-cols-2 gap-8 w-full mt-7 h-[100px]">
          <div>
            <label
              htmlFor="backlink_status"
              className="block text-sm font-medium text-black mb-2"
            >
              Backlink Ready Status
            </label>
            <select
              value={backlinkPayload?.ready_status_id || ""}
              onChange={(e) => {
                setBacklinkPayload((prevState) => ({
                  ...prevState,
                  ready_status_id: e.target.value,
                }));
              }}
              name="backlink_type_id"
              id="backlink_status"
              className="inputField mt-1"
            >
              <option value="">Select Backlink Ready Status</option>
              {backlinksStatusList?.map(({ id, ready_status }) => (
                <option key={id} value={id}>
                  {ready_status.charAt(0).toUpperCase() +
                    ready_status.slice(1).toLowerCase()}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col items-start border-l border-gray-300 pl-5 ">
            <label
              htmlFor="good_to_assign"
              className="block text-sm font-medium text-black"
            >
              Good To Assign
            </label>
            <label className="flex items-center relative w-max cursor-pointer select-none mt-3 ml-4">
              <input
                type="checkbox"
                id="good_to_assign"
                checked={isChecked}
                onChange={handleToggle}
                className="appearance-none transition-colors cursor-pointer w-14 h-6 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-gray-500 checked:bg-green-500"
              />
              <span className="absolute font-medium text-xxs uppercase right-1 text-white">
                No
              </span>
              <span className="absolute font-medium text-xxs uppercase right-8 text-white">
                Yes
              </span>
              <span
                className={`w-6 h-6 right-7 absolute rounded-full transform transition-transform bg-gray-200 ${
                  isChecked ? "translate-x-7" : "translate-x-0"
                }`}
              />
            </label>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TaskListCard;

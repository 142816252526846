import React, { useState, useRef, useEffect } from "react";
import dayjs from "dayjs";
import { Status } from "../../components";
import { IconButton } from "@mui/material";
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from "react-icons/md";
import { IoClose } from "react-icons/io5";
import { FaChevronDown } from "react-icons/fa";
import { toast } from "react-toastify";
import { Badge } from "../../components";
import api from "../../services/api";
import Loading from "../../components/Loader/Loading";
const TaskListCard = ({
  item,
  expandedRows,
  handleExpandClick,
  handleSelectedBatch,
  is_browser_active,
  is_browser_domain,
  timeConvert,
  toggleTaskStatusDropdown,
  openDropdownIndex,
  updateTask,
  setIsUrlModal,
  setTaskStatus,
  user,
  setErrors,
  linkBuilderId,
  selectedStatus,
  handleDelete,
  selectedData,
  setSelectedData,
  linkBuilderUser,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // Activity Dropdown
  const [showActivityList, setShowActivityList] = useState(null);
  const [activityList, setActivityList] = useState([]);
  const [loadingActivity, setLoadingActivity] = useState(null);
  const fetchActivity = (taskId, index) => {
    setIsLoading(true);
    //setLoadingActivity(index);
    api
      .get(`/api/linkbuilder_user/task_activity/${taskId.split(/[^0-9]/)[0]}`)
      .then((response) => {
        setActivityList(response.data);
        if (response.data?.task_activity === null) {
          toast.error("No Task Activity");
          setShowActivityList(null);
          setLoadingActivity(false);
        } else {
          setShowActivityList((prevIndex) =>
            prevIndex === index ? null : index
          );
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLoading(false));
  };

  const dropdownRef = useRef(null);
  const activityRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (activityRef.current && !activityRef.current.contains(event.target)) {
        setShowActivityList(null); // Close the activity list
      }
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        toggleTaskStatusDropdown(null); // Close the dropdown
      }
    };

    if (showActivityList === item.id || openDropdownIndex === item.id) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    showActivityList,
    openDropdownIndex,
    item.id,
    setShowActivityList,
    toggleTaskStatusDropdown,
  ]);
  const renderDetailItem = (label, value, className, link) => {
    if (link) {
      return (
        <p className={className}>
          <b className="text-gray-600 dark:text-white">{label}:</b>
          <a
            href={`${value}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <span className="ml-1 text-sm text-gray-500">{value}</span>
          </a>
        </p>
      );
    } else {
      return (
        <p className={`${className} pb-2 flex items-baseline`}>
          <b className="text-gray-600 dark:text-white">{label}:</b>
          <span className="ml-1 text-sm text-gray-500">{value}</span>
        </p>
      );
    }
  };
  function addSpaceBeforeUpperCase(s) {
    return s && s?.replace(/([A-Z])/g, " $1")?.trim();
  }
  const calculateTotals = (items) => {
    const totals = {
      pending: 0,
      inProcess: 0,
      completed: 0,
      hold: 0,
      inReview: 0,
      cancelled: 0,
      reOpen: 0,
    };
    let totalTasks = 0;
    items?.forEach((item) => {
      const parsedItem = typeof item === "string" ? JSON.parse(item) : item;

      if (totals.hasOwnProperty(parsedItem.task_status)) {
        totals[parsedItem.task_status] += parsedItem.total_backlinks;
        totalTasks += parsedItem.total_backlinks;
      }
    });

    return { totals, totalTasks };
  };
  const { totals, totalTasks } = calculateTotals(item?.items);
  const handleInreviewToPending = (e, prevStatus, itemId) => {
    e.preventDefault();
    if (prevStatus === "inReview") {
      setIsUrlModal(true);
      setTaskStatus({
        rowId: itemId,
        status: "pending",
        prevStatus: prevStatus,
      });
    } else {
      updateTask(itemId, "pending", prevStatus);
    }
  };
  const onSingleSelect = ({ checked, task_id }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [
            ...prevSelectedData,
            task_id?.split(/[^0-9]/)[0],
          ];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (id) => id !== task_id?.split(/[^0-9]/)[0]
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const onSelectAll = (checked) => {
    if (checked) {
      setSelectedData(
        item.childRows?.map((item) => item?.id?.split(/[^0-9]/)[0])
      );
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (task_id) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter((id) => id === task_id?.split(/[^0-9]/)[0])
          .length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      {isLoading && <Loading />}
      <div
        className={`bg-white dark:bg-gray-800 last:border-none shadow-md shadow-black/20 dark:border-white/20"
      }`}
      >
        <div className="grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 border-b py-0">
          <div className="flex items-center text-sm gap-3 flex-wrap justify-betwee">
            {!item.isChild ? (
              <>
                <div>
                  {item?.childRows?.length ? (
                    <input
                      type="checkbox"
                      className="mr-1 w-4 h-4 -m form-checkbox text-primary roundd focus:ring-0 cursor-pointer"
                      onChange={(e) => onSelectAll(e.target.checked)}
                      checked={
                        item?.childRows?.length &&
                        selectedData?.length === item?.childRows?.length
                      }
                    />
                  ) : null}

                  <IconButton
                    onClick={() => {
                      setSelectedData([])
                      if (linkBuilderId) {
                        if (selectedStatus) {
                          handleExpandClick(item.id, selectedStatus);
                        } else {
                          handleExpandClick(item.id, "");
                        }
                      } else {
                        handleExpandClick(item.id, "pending");
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    {expandedRows[item.id] ? (
                      <>
                        <MdKeyboardArrowDown size={22} />
                        <a
                          href={`https://www.${item.domain}`}
                          className="hover:text-secondary underline mx-2 text-sm"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.domain}
                        </a>{" "}
                        <Badge
                          value={
                            linkBuilderId
                              ? selectedStatus === "" || selectedStatus === null
                                ? totalTasks
                                : totals?.[selectedStatus]
                              : totals.pending
                          }
                        />
                      </>
                    ) : (
                      <>
                        <MdKeyboardArrowRight size={22} />
                        <a
                          href={`https://www.${item.domain}`}
                          className="hover:text-secondary underline mx-2 text-sm"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.domain}
                        </a>
                        <Badge
                          value={
                            linkBuilderId
                              ? selectedStatus === "" || selectedStatus === null
                                ? totalTasks
                                : totals?.[selectedStatus]
                              : totals.pending
                          }
                        />
                      </>
                    )}
                  </IconButton>
                </div>
                {selectedData?.length && expandedRows[item.id] ? (
                  <div className="grid grid-cols-3 gap-0">
                    <div></div>
                    <div className="col-span-2 ml-0">
                      <button
                        className="btnPrimary py-2 px-2 text-xs rounded-full capitalize text-red-600 bg-red-100 font-semibold w-[139px]"
                        onClick={() => {
                          handleDelete(selectedData);
                        }}
                      >
                        Delete Selected
                      </button>
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
            {!item.isChild && (
              <>
                {!linkBuilderId ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-7 gap-1 mt-0 cursor-pointer">
                    <div
                      className="flex items-center hover:shadow-md px-2 hover:rounded hover:shadow-black/30 hover:h-8"
                      onClick={() =>
                        handleExpandClick(item.id, "", "childClick")
                      }
                    >
                      <p className="w-30 flex items-center justify-between">
                        <span className="text-gray-500 dark:text-white mr-2 text-sm">
                          Total Count:
                        </span>
                        <Badge value={totalTasks} />
                      </p>
                    </div>
                    <div
                      className="flex items-center hover:shadow-md px-2 hover:rounded hover:shadow-black/30 hover:h-8 "
                      onClick={() =>
                        handleExpandClick(item.id, "inProcess", "childClick")
                      }
                    >
                      <p className="w-30 flex items-center justify-between">
                        <span className="text-gray-500 dark:text-white mr-2 ">
                          In Process Task:
                        </span>
                        <Badge value={totals.inProcess} />
                      </p>
                    </div>
                    <div
                      className="flex items-center hover:shadow-md px-2 hover:rounded hover:shadow-black/30 hover:h-8"
                      onClick={() =>
                        handleExpandClick(item.id, "inReview", "childClick")
                      }
                    >
                      <p className="w-30 flex items-center justify-between">
                        <span className="text-gray-500 dark:text-white mr-2">
                          In Review Task:
                        </span>
                        <Badge value={totals.inReview} />
                      </p>
                    </div>
                    <div
                      className="flex items-center hover:shadow-md px-2 hover:rounded hover:shadow-black/30 hover:h-8"
                      onClick={() =>
                        handleExpandClick(item.id, "hold", "childClick")
                      }
                    >
                      <p className="w-30 flex items-center justify-between">
                        <span className="text-gray-500 dark:text-white mr-2">
                          On Hold Task:
                        </span>
                        <span>
                          <Badge value={totals.hold} />
                        </span>
                      </p>
                    </div>
                    <div
                      className="flex items-center hover:shadow-md px-2 hover:rounded hover:shadow-black/30 hover:h-8"
                      onClick={() =>
                        handleExpandClick(item.id, "cancelled", "childClick")
                      }
                    >
                      <p className="w-30 flex items-center justify-between">
                        <span className="text-gray-500 dark:text-white mr-2">
                          Cancelled Task:
                        </span>
                        <span>
                          <Badge value={totals.cancelled} />
                        </span>
                      </p>
                    </div>
                    <div
                      className="flex items-center hover:shadow-md px-2 hover:rounded hover:shadow-black/30 hover:h-8"
                      onClick={() =>
                        handleExpandClick(item.id, "completed", "childClick")
                      }
                    >
                      <p className="w-30 flex items-center justify-between">
                        <span className="text-gray-500 dark:text-white mr-2">
                          Completed Task:
                        </span>
                        <span>
                          <Badge value={totals.completed} />
                        </span>
                      </p>
                    </div>
                    <div
                      className="flex items-center hover:shadow-md px-2 hover:rounded hover:shadow-black/30 hover:h-8"
                      onClick={() =>
                        handleExpandClick(item.id, "reOpen", "childClick")
                      }
                    >
                      <p className="w-30 flex items-center justify-between">
                        <span className="text-gray-500 dark:text-white mr-2">
                          Reopen Task:
                        </span>
                        <span>
                          <Badge value={totals.reOpen} />
                        </span>
                      </p>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
          {item.isChild ? (
            <div
              className={`overflow-hidden transition-max-height duration-500 ease-in-out px-6 ${
                item.isChild ? "max-h-screen py-3 overflow-x-auto" : "max-h-0"
              }`}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 text-sm">
                <div>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="-ml-6 mr-2 w-4 h-4 mb-1 form-checkbox text-primary roundd focus:ring-0 cursor-pointer"
                      checked={isSelected(item?.id)}
                      onChange={(e) => {
                        onSingleSelect({
                          checked: e.target.checked,
                          task_id: item?.id,
                        });
                      }}
                    />
                    <h2 className="text-base font-medium mb-">
                      Money Site Info
                    </h2>
                  </div>
                  {renderDetailItem("City", item?.city)}
                  {renderDetailItem("Phone", item?.phone)}
                  {renderDetailItem("Domain", item?.domain)}
                </div>
                <div>
                  <h2 className="text-base font-medium mb-1">
                    Backlink URL Info
                  </h2>
                  {renderDetailItem(
                    "Live Status",
                    addSpaceBeforeUpperCase(item?.live_status)
                  )}
                  {renderDetailItem(
                    "Backlink Type",
                    addSpaceBeforeUpperCase(item?.backlink_type)
                  )}
                  {renderDetailItem(
                    "Backlink Domain",
                    addSpaceBeforeUpperCase(item?.backlink_name)
                  )}
                  {renderDetailItem(
                    "Backlink Live URL",
                    item?.link_to_website,
                    "",
                    "link"
                  )}
                </div>
                <div>
                  <h2 className="text-base  font-medium mb-1">Task Details</h2>
                  {renderDetailItem(
                    "Assigned Date",
                    dayjs(item.assigned_date)?.format("ddd, MMM D, YYYY")
                  )}
                  {renderDetailItem("Assigned To", linkBuilderUser?.name)}
                  {renderDetailItem("Batch Number", item?.batch_number)}

                  {/*   <div className="flex items-center gap-1 mt-2">
                    <span className="font-bold text-gray-600 dark:text-white">
                      Activity:
                    </span>
                    <div className="flex flex-col items-center">
                      <button className="flex items-center gap-2">
                        Show Activity
                        {loadingActivity === item.id ? (
                          <img src="/img/loading.gif" className="w-4" alt="" />
                        ) : (
                          <>
                            {showActivityList === item.id ? (
                              <XMarkIcon
                                onClick={() => setShowActivityList(null)}
                                className="w-4 text-red-400"
                              />
                            ) : (
                              <ChevronDownIcon
                                onClick={() => fetchActivity(item.id, item.id)}
                                className="w-4 text-gray-500"
                              />
                            )}
                          </>
                        )}
                      </button>
                      <div className="relative flex justify-end">
                        {showActivityList === item.id && (
                          <div className="bg-white dark:bg-white/20 backdrop-blur-lg text-sm shadow-xl dark:shadow-black mt-1 fixed p-3 rounded-md flex flex-col w-80 z-10 min-h-[100px] max-h-[200px] overflow-y-scroll border dark:border-white/20">
                            {activityList?.task_activity?.map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center justify-between border-b hover:bg-gray-100 dark:hover:bg-white/10 hover:rounded p-2"
                              >
                                <div className="capitalize">
                                  {addSpaceBeforeUpperCase(item.status)}
                                </div>
                                <div>{timeConvert(item.timestamp)}</div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <div className="flex items-center gap-1 mt-0">
                      <span className="font-bold text-gray-500 dark:text-white">
                        Activity:
                      </span>
                      <div
                        className="flex flex-col items-center relative"
                        ref={activityRef}
                      >
                        <button className="flex items-center gap-2">
                          Show Activity
                          {loadingActivity === item.id ? (
                            <img
                              src="/img/loading.gif"
                              className="w-4"
                              alt=""
                            />
                          ) : (
                            <>
                              {showActivityList === item.id ? (
                                <IoClose
                                  onClick={() => setShowActivityList(null)}
                                  className="w-6 text-red-400"
                                  size="22"
                                />
                              ) : (
                                <FaChevronDown
                                  onClick={() =>
                                    fetchActivity(item.id, item.id)
                                  }
                                  size="20"
                                  className="w-3 text-black font-semibold"
                                />
                              )}
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="relative flex justify-between">
                      {showActivityList === item.id && (
                        <>
                          <div className="bg-white dark:bg-white/20 backdrop-blur-lg text-sm shadow-xl dark:shadow-black mt-1 p-3 rounded-md flex flex-col w-80  min-h-[100px] max-h-[150px] overflow-y-scroll border dark:border-white/20 ">
                            {activityList?.task_activity?.map((item, index) => (
                              <div
                                key={index}
                                className="flex items-center justify-between border-b hover:bg-gray-100 dark:hover:bg-white/10 hover:rounded p-2"
                              >
                                <div className="capitalize">
                                  {" "}
                                  {addSpaceBeforeUpperCase(item.status)}
                                </div>
                                <div>{timeConvert(item.timestamp)}</div>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className="grid grid-cols-3 gap-0">
                    <div></div>
                    <div className="col-span-2 mb-3 ml-0">
                      <button
                        className="btnPrimary py-2 px-2 text-xs rounded-full capitalize text-[#11223c] font-semibold w-[139px]"
                        onClick={() => {
                          // if (
                          //   +is_browser_active &&
                          //   +is_browser_active === 1 &&
                          //   is_browser_domain !== item.domain
                          // )
                          //   return toast.error(
                          //     "Please stop the previous browser"
                          //   );
                          handleSelectedBatch(item);
                        }}
                      >
                        Start Task
                      </button>
                    </div>
                  </div>
                  <div className="grid grid-cols-3 gap-0">
                    <span className="font-bold text-gray-600 dark:text-white mt-2">
                      Task Status:
                    </span>
                    <Status type={item.task_status} />
                    <div className="flex items-center gap-2">
                      <button
                        onClick={() => toggleTaskStatusDropdown(item.id)}
                        className="absolute"
                      >
                        {openDropdownIndex === item.id ? (
                          <IoClose className="w-6 text-red-400" size={22} />
                        ) : (
                          <FaChevronDown
                            className="w-3 text-gray-500"
                            size="20"
                          />
                        )}
                        <div
                          className="relative flex justify-end"
                          ref={dropdownRef}
                        >
                          {openDropdownIndex === item.id && (
                            <div className="bg-white dark:bg-dark dark:shadow-black shadow-md absolute top-full left-1/2 transform -translate-x-1/2 text-left p-2 mt-1 rounded-md flex flex-col w-32 z-50">
                              <button
                                onClick={(e) =>
                                  handleInreviewToPending(
                                    e,
                                    item.task_status,
                                    item.id
                                  )
                                }
                                className={`py-2 px-4 hover:bg-gray-50 text-red-600 dark:text-red-400 rounded ${
                                  item.task_status === "pending" && "bg-red-100"
                                }`}
                              >
                                Pending
                              </button>
                              <button
                                onClick={() => {
                                  updateTask(item.id, "inProcess");
                                }}
                                className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                  item.task_status === "inProcess" &&
                                  "bg-indigo-100"
                                }`}
                              >
                                In Process
                              </button>
                              <button
                                onClick={() => {
                                  updateTask(item.id, "inReview");
                                }}
                                className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                  item.task_status === "inReview" &&
                                  "bg-indigo-100"
                                }`}
                              >
                                In Review
                              </button>
                              <button
                                onClick={() => {
                                  setIsUrlModal(true);
                                  setErrors({});
                                  setTaskStatus({
                                    rowId: item.id,
                                    status: "hold",
                                  });
                                }}
                                className={`py-2 px-4 hover:bg-gray-50 text-gray-600 dark:text-gray-300 rounded ${
                                  item.task_status === "hold" && "bg-gray-100"
                                }`}
                              >
                                Hold
                              </button>
                              <button
                                onClick={() => {
                                  setIsUrlModal(true);
                                  setErrors({});
                                  setTaskStatus({
                                    rowId: item.id,
                                    status: "cancelled",
                                  });
                                }}
                                className={`py-2 px-4 hover:bg-gray-50 text-red-600 dark:text-gray-300 rounded ${
                                  item.task_status === "cancelled" &&
                                  "bg-gray-100"
                                }`}
                              >
                                Cancelled
                              </button>
                              <button
                                onClick={() => {
                                  updateTask(item.id, "completed");
                                }}
                                className={`py-2 px-4 hover:bg-gray-50 text-green-600 dark:text-green-300 rounded ${
                                  item.task_status === "completed" &&
                                  "bg-gray-50"
                                }`}
                              >
                                Completed
                              </button>
                              <button
                                onClick={() => {
                                  setIsUrlModal(true);
                                  setErrors({});
                                  setTaskStatus({
                                    rowId: item.id,
                                    status: "reOpen",
                                  });
                                }}
                                className={`py-2 px-4 hover:bg-gray-50 text-indigo-600 dark:text-indigo-300 rounded ${
                                  item.task_status === "reOpen" && "bg-gray-50"
                                }`}
                              >
                                Reopen
                              </button>
                            </div>
                          )}
                        </div>
                      </button>
                    </div>

                    {/* <button
                    className="btnPrimary py-1 rounded-full text-xs px-3 ml-2 capitalize text-[#11223c] font-semibold"
                    onClick={() => {
                      if (
                        +is_browser_active &&
                        +is_browser_active === 1 &&
                        +is_browser_domain !== item.domain
                      )
                        return toast.error("Please stop the previous browser");
                      handleSelectedBatch(item);
                    }}
                  >
                    start task
                  </button> */}
                  </div>
                  <div className="grid grid-cols-3 gap-0">
                    <div></div>
                    <div className="col-span-2 mt-3 ml-0">
                      <button
                        className="btnPrimary disabled:cursor-not-allowed py-2 px-2 text-xs rounded-full capitalize text-red-600 bg-red-100 font-semibold w-[139px]"
                        onClick={() => {
                          handleDelete(item?.id);
                        }}
                        disabled={selectedData?.length}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        {expandedRows[item.id] &&
        item.childRows &&
        item.childRows.length === 0 ? (
          <div className="grid md:grid-cols-domainCard items-center px-6 dark:border-white/20 border-t py-0 bg-gray-100">
            <div className="flex justify-center px-3 py-3 font-normal text-sm">
              No Records
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TaskListCard;

import React, { useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../components/Loader/Loading";
import api from "../../services/api";
import MUIDataTable from "../../components/MUIGrid";
export default function AddVerifyBacklink({
  selectedData,
  cancelButtonModal,
  isExistingData,
  user,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleAssignBacklink = async (param) => {
    setIsLoading(true);
    try {
      const saveDataArr = {
        domains: selectedData?.domains?.map((item) => item["d.domain"]) || [],
        ...(user && { user_id: user }),
        is_confirmed: true,
        backlink_ids: selectedData?.backlinks?.map((item) => item["b.id"]),
      };
      const res = await api.post("api/domains/assign_backlinks", saveDataArr);
      if (res.status === 200 || res.status === 201) {
        toast.success("Backlinks assigned successfully");
      } else {
        toast.error(res.message);
      }
    } catch (err) {
      toast.error(err.response?.data?.message || "Failed to assign backlinks!");
    } finally {
      setIsLoading(null);
    }
  };
  const UserDetails = () => {
    const userName = Object.keys(isExistingData?.user_details)[0];
    const userStatus = isExistingData?.user_details[userName];
    return (
      <div className="bg-white my-2 border rounded mt-3">
        <div className="mb-2">
          <h2 className="text-xl font-semibold my-2 mx-2 text-gray-500">
            Selected Link Builder - {userName}
          </h2>
        </div>
        <div className="grid grid-cols-7 gap-1 text-center">
          <div className="bg-gray-100 p-2 rounded">Pending</div>
          <div className="bg-gray-100 p-2 rounded">In Process</div>
          <div className="bg-gray-100 p-2 rounded">In Review</div>
          <div className="bg-gray-100 p-2 rounded">Hold</div>
          <div className="bg-gray-100 p-2 rounded">Cancelled</div>
          <div className="bg-gray-100 p-2 rounded">Completed</div>
          <div className="bg-gray-100 p-2 rounded">Reopen</div>
          <div className="bg-white p-2 rounded font-bold">
            {userStatus.pending || 0}
          </div>
          <div className="bg-white p-2 rounded font-bold">
            {userStatus.inProcess || 0}
          </div>
          <div className="bg-white p-2 rounded font-bold">
            {userStatus.inReview || 0}
          </div>
          <div className="bg-white p-2 rounded font-bold">
            {userStatus.hold || 0}
          </div>
          <div className="bg-white p-2 rounded font-bold">
            {userStatus.cancelled || 0}
          </div>
          <div className="bg-white p-2 rounded font-bold">
            {userStatus.completed || 0}
          </div>
          <div className="bg-white p-2 rounded font-bold">
            {userStatus.reOpen || 0}
          </div>
        </div>
      </div>
    );
  };
  const columnDefs = [
    {
      headerName: "Domain",
      minWidth: 180,
      field: "domain",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <a
            href={`https://www.${params.row.domain}`}
            className="hover:text-secondary underline"
            target="_blank"
            rel="noreferrer"
          >
            {params.row.domain}
          </a>
        );
      },
    },
    {
      headerName: "Existing Task",
      field: "is_exist",
      minWidth: 120,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row.backlinks?.length}
          </span>
        );
      },
    },
    {
      headerName: "Requested Task",
      field: "request_task",
      flex: 1,
      align: "center",
      minWidth: 130,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {selectedData?.domains?.length}
          </span>
        );
      },
    },
    {
      headerName: "Pending ",
      field: "total_pending",
      flex: 1,
      align: "center",
      minWidth: 80,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row?.statuses?.pending || 0}
          </span>
        );
      },
    },
    {
      headerName: "In Process",
      field: "total_inprocess",
      flex: 1,
      align: "center",
      minWidth: 90,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row?.statuses?.inProcess || 0}
          </span>
        );
      },
    },
    {
      headerName: "In Review",
      field: "total_inreview",
      flex: 1,
      minWidth: 90,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row?.statuses?.inReview || 0}
          </span>
        );
      },
    },
    {
      headerName: "Hold",
      field: "total_hold",
      flex: 1,
      minWidth: 60,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row?.statuses?.hold || 0}
          </span>
        );
      },
    },
    {
      headerName: "Cancelled",
      field: "total_cancelled",
      flex: 1,
      minWidth: 90,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row?.statuses?.cancelled || 0}
          </span>
        );
      },
    },
    {
      headerName: "Completed ",
      field: "total_completed",
      flex: 1,
      minWidth: 90,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row.statuses?.completed || 0}
          </span>
        );
      },
    },
    {
      headerName: "Reopen ",
      field: "total_reopen",
      flex: 1,
      minWidth: 90,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <span className="text-center bg-[#93c5fd] py-1 px-2 rounded hover:cursor-pointer">
            {params.row.statuses?.reOpen || 0}
          </span>
        );
      },
    },
  ];
  const domainBacklinksMap = isExistingData?.existing_tasks?.reduce(
    (acc, task) => {
      acc[task.domain] = task.backlinks;
      return acc;
    },
    {}
  );

  const transformedArray = isExistingData?.domain_detail?.map((detail) => {
    const backlinks = domainBacklinksMap[detail.domain] || [];
    return {
      domain: detail.domain,
      statuses: detail.statuses,
      backlinks: backlinks,
    };
  });

  return (
    <div className="p-2 px-2 lg:px-2">
      {isLoading && <Loading />}
      <div className="flex p-0 mt-0">
        <div className="flex-1 p-4 shadow w-full">
          <div className="font-semibold text-xl mb-2 text-gray-500 ml-2">
            Summary of Batch
          </div>
          <div className="flex-cols md:flex flex-wrap justify-start space-x-2 mx-0.5 mt-0">
            <button
              type="button"
              className="text-sm hover:bg-blue-700 py-2 px-2 rounded btnTable bg-white text-black"
            >
              Total Requested Task
              <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
                {isExistingData?.total_requested_tasks || 0}
              </span>
            </button>
            <button
              type="button"
              className="text-sm hover:bg-blue-700 py-2 px-2 rounded btnTable bg-white text-black"
            >
              Already Created Task
              <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
                {isExistingData?.already_created_tasks || 0}
              </span>
            </button>
            <button
              type="button"
              className="text-sm hover:bg-blue-700 py-2 px-2 rounded btnTable bg-white text-black"
            >
              Available To Create
              <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
                {isExistingData?.available_to_create || 0}
              </span>
            </button>
            <button
              type="button"
              className="text-sm hover:bg-blue-700 py-2 px-2 rounded btnTable bg-white text-black"
            >
              Total Selected Backlinks
              <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
                {isExistingData?.total_selected_backlinks || 0}
              </span>
            </button>
            <button
              type="button"
              className="text-sm hover:bg-blue-700 py-2 px-2 rounded btnTable bg-white text-black"
            >
              Total Selected Domains
              <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
                {isExistingData?.total_selected_domains || 0}
              </span>
            </button>
          </div>
          {Object.keys(isExistingData?.user_details).length > 0 &&
            UserDetails()}
          <div className="mt-5 flex flex-col flex-1">
            <div className="font-semibold text-xl mb-2 text-gray-500 ml-2">
              Selected Domain Report
            </div>
            <div className="bg-white my-2 border rounded">
              <MUIDataTable
                columnDefs={columnDefs}
                items={transformedArray?.map((data, index) => {
                  const { domain, statuses, backlinks } = data;
                  return {
                    records: data,
                    counter: index + 1,
                    domain,
                    statuses,
                    backlinks,
                  };
                })}
                searchable="No"
                showCount="No"
                toolbar="No"
                height="40vh"
                pagination="No"
                columnHeaderHeight="100"
                hideFooter
              />
            </div>
            <div className="flex items-center mt-5 justify-end space-x-2">
              <button
                type="button"
                onClick={handleAssignBacklink}
                className="btnPrimary bg-green-600 text-white py-2 px-4 rounded"
              >
                Ignore & Submit
              </button>
              <button
                onClick={cancelButtonModal}
                type="button"
                className="btnPrimary bg-red-500 text-white py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { QueueListIcon, RectangleGroupIcon } from "@heroicons/react/24/solid";
import {
  HiUserCircle,
  HiUserGroup,
  HiViewGrid,
  HiLink,
  HiGlobe,
  HiCog,
} from "react-icons/hi";

export const menuItems = {
  dashboard: {
    to: "/dashboard",
    label: "Dashboard",
    Icon: HiViewGrid,
    allowedRoles: [1, 3],
  },
  tasklist: {
    to: "/task-list",
    label: "Task List",
    Icon: QueueListIcon,
    allowedRoles: [3],
  },
  settings: {
    to: "/dashboard/settings",
    label: "Settings",
    Icon: HiCog,
    allowedRoles: [1], // Accessible by role level 1
    children: {
      // linkBuilders: {
      //   to: "/settings/link-builders",
      //   label: "Link Builders",
      //   Icon: HiUserGroup,
      //   allowedRoles: [1],
      // },
      // linkBuildersReport: {
      //   to: "/link-builders/reports",
      //   label: "Link Builders Reports",
      //   Icon: HiUserGroup,
      //   allowedRoles: [1],
      // },
      users: {
        to: "/settings/users",
        label: "Users",
        Icon: HiUserGroup,
        allowedRoles: [1],
      },
      roles: {
        to: "/settings/roles",
        label: "Roles",
        Icon: HiUserCircle,
        allowedRoles: [1],
      },
      permissions: {
        to: "/settings/permissions",
        label: "Permissions",
        Icon: HiUserCircle,
        allowedRoles: [1],
      },
      templates: {
        to: "/settings/templates",
        label: "Templates",
        Icon: HiLink,
        allowedRoles: [1],
      },
      merchants: {
        to: "/settings/merchants",
        label: "Merchants",
        Icon: HiUserGroup,
        allowedRoles: [1],
      },
      copyUrlButton: {
        label: "Renew Mail Token",
        Icon: HiLink, 
        allowedRoles: [1], 
        action: () => { 
          const url = "https://api.roboanywhere.com/api/public/request_refresh_token";
          navigator.clipboard.writeText(url).then(() => {
            alert(`URL copied: ${url}`);
          }).catch(err => {
            console.error('Failed to copy URL:', err);
          });
        },
      },
      holdReason: {
        to: "/settings/hold-reason",
        label: "Hold Reason",
        Icon: HiLink,
        allowedRoles: [1],
      },
      nicheProduct: {
        to: "/settings/niche-products",
        label: "Niche Products",
        Icon: HiLink,
        allowedRoles: [1],
      },
    },
  },

  backlinks: {
    to: "/backlinks",
    label: "Backlinks",
    Icon: HiLink,
    allowedRoles: [1],
    children: {
      list: {
        to: "/backlinks/backlinks-list",
        label: "Backlinks",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinksType: {
        to: "/backlinks/backlinks-type",
        label: "Backlink Type",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinksContent: {
        to: "/backlinks/content",
        label: "Backlink Content",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinkReadyStatus: {
        to: "/backlinks/backlink-ready-status",
        label: "Backlink Ready Status",
        Icon: HiLink,
        allowedRoles: [1],
      },
    },
  },

  batches: {
    to: "/batches",
    label: "Batches",
    Icon: RectangleGroupIcon,
    allowedRoles: [1],
  },

  domains: {
    to: "/domains",
    label: "Domains",
    Icon: HiGlobe,
    allowedRoles: [1],
  },
  reports: {
    to: "/reports",
    label: "Reports",
    Icon: HiLink,
    allowedRoles: [1],
    children: {
      taskReport: {
        to: "/reports/link-builders",
        label: "Linkbuilders Tasks Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      performanceReport: {
        to: "/reports/performance-report",
        label: "Linkbuilders Performance Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      domainReport: {
        to: "/reports/money-domain-report",
        label: "Money Domain Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      reviewTaskReport: {
        to: "/reports/review-task",
        label: "Review Tasks",
        Icon: HiLink,
        allowedRoles: [1],
      },
      backlinkPerforReport: {
        to: "/reports/backlink-performance-report",
        label: "Backlink Performance Report",
        Icon: HiLink,
        allowedRoles: [1],
      },
      dailyReport: {
        to: "/reports/daily-tasks",
        label: "Daily Tasks",
        Icon: HiLink,
        allowedRoles: [1],
      },
      reviewDailyTasks: {
        to: "/reports/review-daily-tasks",
        label: "Review Daily Tasks",
        Icon: HiLink,
        allowedRoles: [1],
      },
    },
  },
};

// export const sideMenu = Object.keys(menuItems).reduce((acc, key) => {
//   const menuItem = menuItems[key];
//   if (user?.permissions?.some((obj) => obj === menuItem?.label)) {
//     acc.push({
//       ...menuItem,
//       key,
//     });
//   }
//   return acc;
// }, [user]);

import React from "react";
import dayjs from "dayjs";
import MUIDataTable from "../../components/MUIGrid";

const FullPagePopup = ({ backlinksData }) => {
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 100,
        page: 1,
    });
    const columns = [
        { field: 'counter', headerName: 'Sr#', width: 90 },
        {
            field: 'backlink_name',
            headerName: 'Backlink Domain',
            width: 150,
            hideable: false,
            renderCell: (params) => {
                return (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://${params?.value}`}
                        className="text-black no-underline hover:underline cursor-poi"
                    >
                        {params?.value}
                    </a>
                );
            }
        },
        {
            field: 'backlink_type',
            headerName: 'Backlink Type',
            width: 150,
            editable: true,
        },
        {
            field: 'ready_status',
            headerName: 'Backlink Ready Status',
            width: 150,
            editable: true,
        },
        {
            field: 'city',
            headerName: 'Location',
            width: 150,
            editable: true,
        },
        {
            field: 'created_at',
            headerName: 'Created At',
            width: 210,
            editable: true,
            filterable: false,
        },
        {
            field: 'updated_at',
            headerName: 'Updated At',
            width: 210,
            editable: true,
            filterable: false,
        }
    ];
    const handlePageSizeChange = (newPageSize) => {
        setPaginationModel({ ...paginationModel, pageSize: newPageSize });
    };
    const handlePageChange = (params) => {
        setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    };
    
    return (
        <MUIDataTable
            columnDefs={columns}
            items={(backlinksData.length ? backlinksData : [])?.map((record, index) => {
                const {
                    backlink_name,
                    backlink_type,
                    ready_status,
                    city
                } = record;
                const created_at = dayjs(record["created_at"]).format(
                    "ddd, MMM D, YYYY h:mm A"
                );
                const updated_at = dayjs(record["updated_at"]).format(
                    "ddd, MMM D, YYYY h:mm A"
                );
                return {
                    counter: index+1,
                    backlink_name,
                    backlink_type,
                    ready_status,
                    city,
                    created_at,
                    updated_at
                };
            })}
            totalItems={backlinksData?.length}
            height="88vh"
            paginationModel={paginationModel}
            searchable="No"
            showCount="No"
            sortingMode="client"
            paginationMode="client"
            onPaginationModelChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
        />
    );
};

export default FullPagePopup;

import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import { MdDelete } from "react-icons/md";
import { HiUserGroup } from "react-icons/hi";
import { FaSearch, FaLink  } from "react-icons/fa";
import { Modal } from "../../components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import LinkBuilderBatchesList from "./LinkBuilderBatchesList";
import DomainListOfUserBatch from "./DomainListOfUserBatch";
import DomainReport from "./DomainReport";
import api from "../../services/api";
import AddUpdateUserForm from "./AddUpdateUserForm";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import AssignUsersModal from "./AssignUsersModal";
import { TbWorldWww } from "react-icons/tb";
import { useSelector, useDispatch } from "react-redux";
import { get_merchants } from "../../store/slices/merchantSlice";
import MUIDataTable from "../../components/MUIGrid";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton, TablePagination } from "@mui/material";
import CustomBreadcrumbs from "../../components/common/CustomBreadcrumbs";
export default function Users() {
  //const location = useLocation();
  //const currentPath = location.pathname;
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  //const [batchesList, setBatchesList] = useState([]);
  const [domainReport, setDomainReport] = useState([]);
  const [modalFormStep, setModalFormStep] = useState(1);
  const [selectedUser, setSelectedUser] = useState({});
  //const [userListFilter, setUserListFilter] = useState("all");
  const [selectedDomain, setSelectedDomain] = useState({});
  const [modalType, setModalType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUserAssignModal, setUsersAssignModal] = useState(false);
  const [isSubUsers, setSubUsers] = useState([]);
  const { merchants } = useSelector((state) => state.merchant);
  const dispatch = useDispatch();
  //const defaultFilter = [{ field: "role_id", op: "isNoneOf", data: [3, 7, 8] }];
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  const handleUserAssignModal = () => {
    setUsersAssignModal(!isUserAssignModal);
  };

  const get_user = async (filter) => {
    setIsLoading(true);
    let payload = { ...filter };
    /* if (!payload.filters?.rules?.find(({ field }) => field === "role_id")) {
      payload.filters?.rules.push(defaultFilter[0]);
    } */
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    try {
      const res = await api.post("/api/users/users_report", payload);
      if (res.status === 200) {
        setUsers(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("🚀 ~ getSERPReport ~ err:", err);
    }
  };
  const cancelButtonRef2 = useRef(null);
  useEffect(() => {
    get_user({
      ...paginationModel,
      perPage: paginationModel.pageSize,
      filters: queryOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsLoading(true);
    // api
    //   .get(`${process.env.REACT_APP_PUBLIC_API}/api/users`)
    //   .then((response) => {
    //     setUsers(response.data);
    //     if (currentPath === "/settings/link-builders") {
    //       setUserListFilter("Link Builder");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/roles`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
    dispatch(get_merchants());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewUser = () => {
    setModalType("newUser");
    handleModal();
  };

  const handleEditUser = (user) => {
    setModalType("editUser");
    setSelectedUser(user);
    handleModal();
  };

  /*   const fetchUsersData = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `${process.env.REACT_APP_PUBLIC_API}/api/users`
      );
      setUsers(response.data);
    } catch (error) {
      console.error(`Error fetching users data: ${error}`);
    }
    setIsLoading(false);
  }; */

  /* const getBatchesList = async (user) => {
    setBatchesList([]);
    setDomainReport([]);
    setSelectedUser(user);
    setModalType("batchesList");
    api
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/linkBuilderTask/linkbuilderBatchesList/${user.id}`
      )
      .then((response) => {
        setBatchesList(response.data);
        handleModal();
      })
      .catch(() => {
        toast.error(`No Domains Assigned To ${user.username}`, {
          autoClose: 1500,
        });
      });
  }; */

  const [domainList, setDomainList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState({});
  const getBatchDomainList = async (batch) => {
    setDomainList([]);
    setSelectedBatch(batch);
    api
      .get(
        `${
          process.env.REACT_APP_PUBLIC_API
        }/api/linkBuilderTask/batchLinkbuilderDomainList?user_id=${
          selectedUser.id
        }&batch_id=${batch.batch_id || batch.id}`
      )
      .then((response) => {
        setDomainList(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    setModalFormStep(2);
  };
  const getDomainReport = (domain) => {
    setDomainReport([]);
    setSelectedDomain(domain);
    api
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/linkBuilderTask?user_id=${domain["user_id"]}&batch_id=${domain["batch_id"]}&domain_id=${domain["domain_id"]}`
      )
      .then((response) => setDomainReport(response.data))
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setModalFormStep(3));
  };

  const navigate = useNavigate();
  /*   const handleGroupListHasBatch = (user) => {
    navigate(`/users/${user.name}`);
  }; */
  const handleAssign = (param, sub_users = null) => {
    setUsersAssignModal(param);
    setSubUsers(sub_users);
  };
  const assignDomainsToUser = (user) => {
    navigate(`/assign_domains_to_user`, {
      state: {
        selectedUser: user,
      },
    });
  };
  const assignBacklink = (user) => {
    navigate(`/backlinks/backlinks-list`, {
      state: {
        selectedUser: user,
        module:"backlink_from_user",
      },
    });
  };
  
  function getCircleSize(count) {
    const digitCount = count.toString().length;
    if (digitCount === 1) {
      return "w-4 h-4 text-xs ml-2"; // Small size for 1 digit
    } else if (digitCount === 2) {
      return "w-5 h-5 text-xxs ml-2"; // Medium size for 2 digits
    } else if (digitCount === 3) {
      return "w-6 h-6 text-xxs ml-4"; // Medium size for 3 digits
    } else if (digitCount === 4) {
      return "w-7 h-7 text-xxs ml-4"; // Medium size for 4 digits
    } else if (digitCount === 5) {
      return "w-8 h-8 ml-5 text-xxs"; // Medium size for 5 digits
    } else {
      return "w-10 h-10 ml-5 text-xxs"; // Larger size for 3 or more digits
    }
  }
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    get_user({
      filters: queryOptions,
      page: +params.page + 1,
      size: params.pageSize,
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      get_user({
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    get_user({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    get_user({
      filters: queryOptions,
      page: +page + 1,
      perPage: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    get_user({
      filters: queryOptions,
      page: 1,
      perPage: newPageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="flex items-center justify-between">
        <div className="flex">
          <GridToolbarColumnsButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarDensitySelector
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          />
          <GridToolbarFilterButton
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            ref={setFilterButtonEl}
          />
          <MUIButton
            variant="text"
            onClick={handleNewFilter}
            startIcon={<FaSearch size={16} />}
            sx={{
              borderRadius: "6px",
              marginRight: "4px",
              border: "1px solid",
              borderColor: "#e8eaee",
              height: "26px",
              paddingLeft: 1,
              paddingRight: 1,
            }}
          >
            Apply filter
          </MUIButton>
        </div>
        <div className="flex items-center">
          <TablePagination
            color="primary"
            shape="rounded"
            size="medium"
            showFirstButton
            showLastButton
            count={users?.count ? users?.count : users?.records?.length || 0}
            page={paginationModel.page - 1 || 0}
            onPageChange={(e, page) => handleTopPageChange(page)}
            rowsPerPage={paginationModel.pageSize || 100}
            onRowsPerPageChange={(e) => handleTopPageSizeChange(e.target.value)}
            component="div"
            rowsPerPageOptions={[
              25,
              50,
              75,
              100,
              250,
              500,
              1000,
              1500,
              2000,
              {
                label: "All",
                value: users?.count
                  ? users?.count
                  : users?.records?.length || 0,
              },
            ]}
          />
          {userPermission("Create User") ? (
            <button className="btnPrimary ml-3 mr-2" onClick={handleNewUser}>
              Create User
            </button>
          ) : null}
        </div>
      </GridToolbarContainer>
    );
  }
  const columnDefs = [
    { headerName: "Sr#", field: "counter", width: 80, filterable: false },
    {
      headerName: "Name",
      field: "u.name",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => params.row?.name,
    },
    {
      headerName: "Email",
      field: "u.email",
      flex: 1,
      minWidth: 180,
      renderCell: (params) => params.row.email,
    },
    {
      headerName: "Role",
      field: "r.id",
      flex: 1,
      minWidth: 180,
      type: "singleSelect",
      getOptionValue: (value) => value?.id,
      getOptionLabel: (value) => value.name,
      valueOptions: roles,
      renderCell: (params) => params.row.role,
    },
    // { headerName: "Active", field: "is_active", width: 100 },
    {
      headerName: "Active",
      field: "is_active",
      maxWidth: 120,
      filterable: true,
      type: "singleSelect",
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
      ],
      renderCell: (params) => params.value?.is_active,
      valueGetter: (params) => params.row?.is_active,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "Actions",
      field: "role",
      minWidth: 200,
      filterable: false,
      renderCell: (params) => {
        const assignDomainCount = params.row.records?.assigned_domains;
        const assignBacklinksCount = params.row.records?.assigned_backlinks;
        
        return (
          <div className="relative">
            <div className="flex items-center justify-center">
              {userPermission("Edit User") ? (
                <PencilSquareIcon
                  key={`${params?.row.username}-action`}
                  className="h-5 w-5 cursor-pointer text-black-400"
                  onClick={() => handleEditUser(params.row.records)}
                />
              ) : null}
              {userPermission("Delete User") ? (
                <div
                  className="relative ml-1 cursor-pointer"
                  onClick={(e) => deleteUser(params.row.records.id)}
                >
                  <MdDelete
                    className="h-5 w-5 text-black-400 relative z-10"
                    title="Delete User"
                    size={18}
                  />
                </div>
              ) : null} 
              <div
                className={`relative ml-1 cursor-pointer ${
                  assignBacklinksCount.toString().length === 1 ||
                  assignBacklinksCount.toString().length === 2
                    ? "mr-2"
                    : "mr-5"
                }`}
                onClick={() => assignBacklink(params.row.records)}
                title="Assign Backlink To User"
              >
                <FaLink
                  className="h-5 w-5 text-black-400 relative z-10"
                  title="Assign Domains"
                />
                {assignBacklinksCount && assignBacklinksCount !== 0 ? (
                  <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                    <div
                      className={`bg-green-500 text-white rounded-full flex items-center justify-center 
                ${getCircleSize(assignBacklinksCount)}`}
                    >
                      {assignBacklinksCount}
                    </div>
                  </div>
                ) : null}
              </div>
           {/*    <div
                className={`relative ml-1 cursor-pointer ${
                  assignDomainCount.toString().length === 1 ||
                  assignDomainCount.toString().length === 2
                    ? "mr-2"
                    : "mr-5"
                }`}
                onClick={() => assignDomainsToUser(params.row.records)}
              >
                <TbWorldWww
                  className="h-6 w-6 text-black-400 relative z-10"
                  title="Assign Domains"
                />
                {assignDomainCount && assignDomainCount !== 0 ? (
                  <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                    <div
                      className={`bg-green-500 text-white rounded-full flex items-center justify-center 
                ${getCircleSize(assignDomainCount)}`}
                    >
                      {assignDomainCount}
                    </div>
                  </div>
                ) : null}
              </div> */}
              <div
                className="relative ml-2 cursor-pointer"
                onClick={() => {
                  setSelectedUser(params.row.records);
                  handleAssign(true, params.row.records?.sub_users);
                }}
              >
                <HiUserGroup
                  className="h-5 w-5 text-black-400 relative z-10"
                  title="Assign Users"
                />
                {params.row.records?.sub_users && (
                  <div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2">
                    <div
                      className={`bg-green-500 text-white rounded-full flex items-center justify-center ${getCircleSize(
                        (params.row.records?.sub_users ?? "")
                          .split(",")
                          .filter(Boolean).length
                      )}`}
                    >
                      {
                        (params.row.records?.sub_users ?? "")
                          .split(",")
                          .filter(Boolean).length
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];
  const updateUsersData = async () => {
    get_user({
      ...paginationModel,
      perPage: paginationModel.pageSize,
      filters: queryOptions,
    });
  };
  const deleteUser = (id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (!isConfirmed) return;
    api
      .delete(`/api/users/${id}`)
      .then((res) => {
        toast.success("User Removed Successfully.");
        get_user({
          ...paginationModel,
          perPage: paginationModel.pageSize,
          filters: queryOptions,
        });
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  const getRowHeight = (params) => {
    const baseHeight = 52;
    const contentLength = 32;
    const lines = Math.ceil(contentLength / 30);
    return baseHeight + (lines - 1) * 20;
  };
  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Users", link: "" },
        ]}
      />
      <ToastContainer />
      {isLoading && <Loading />}
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={users?.records?.map((data, index) => {
            const { name, username, email,role_id, role, is_active } = data;
            return {
              records: data,
              counter: index + 1,
              name,
              username,
              email,
              role_id,
              role,
              is_active: is_active ? "Active" : "In Active",
            };
          })}
          totalItems={users?.count}
          paginationModel={paginationModel}
          CustomToolbar={CustomToolbar}
          height="88vh"
          searchable="No"
          showCount="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          gridOptions={getRowHeight}
        />
      </div>
      {(modalType === "editUser" || modalType === "newUser") && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          className="max-w-5xl"
          title={
            (modalType === "newUser" && "Create New User") ||
            (modalType === "editUser" && "Edit User Info")
          }
        >
          <AddUpdateUserForm
            handleModal={handleModal}
            fetchUsersData={updateUsersData}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            modalType={modalType}
            user={selectedUser}
            roles={roles}
            merchantList={merchants}
          />
        </Modal>
      )}
      {modalType === "batchesList" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          modalFormStep={modalFormStep}
          setModalFormStep={setModalFormStep}
          className="max-w-3xl"
          isLoading={isLoading}
          title={
            (modalFormStep === 1 &&
              `Batches List Of ${selectedUser["name"]}`) ||
            (modalFormStep === 2 &&
              `Domain List Of ${selectedBatch["batch_name"]}`) ||
            (modalFormStep === 3 && (
              <>
                <span className="lowercase">{selectedDomain["domain"]}</span>{" "}
                Report
              </>
            ))
          }
        >
          {modalFormStep === 1 && (
            <LinkBuilderBatchesList
              getBatchDomainList={getBatchDomainList}
              // batchesList={batchesList}
            />
          )}
          {modalFormStep === 2 && (
            <DomainListOfUserBatch
              domainList={domainList.domainList}
              getDomainReport={getDomainReport}
            />
          )}
          {modalFormStep === 3 && <DomainReport domainReport={domainReport} />}
        </Modal>
      )}
      <Modal
        open={!isUserAssignModal}
        handleModal={handleUserAssignModal}
        cancelButtonRef={cancelButtonRef2}
        className="max-w-4xl"
        title="Users"
      >
        <AssignUsersModal
          handleModal={handleModal}
          isLoading={isLoading}
          roles={roles}
          handleAssign={handleAssign}
          isSubUsers={isSubUsers}
          selectedUser={selectedUser}
          fetchUsersListing={updateUsersData}
        />
      </Modal>
    </div>
  );
}

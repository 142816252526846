import {
  Backlinks,
  Dashboard,
  DomainBatchesList,
  GroupDomainList,
  GroupLinkbuilderBatchList,
  GroupListHasBatch,
  TaskList,
  UpdateUserDomainLinks,
  Users,
  Batches,
  Batch,
  TaskDetail,
  LinkBuilders,
  BacklinksType,
  BacklinkReadyStatus,
  BacklinksContent,
  AssignBacklinks,
  LinkBuildersReports,
  Merchants,
  NewBatches,
} from "../pages";
import Roles from "../pages/Roles";
import Templates from "../pages/Templates";
import Permissions from "../pages/Permissions";
import Mailbox from "../pages/Mailbox";
import InboxDetail from "../pages/Mailbox/InboxDetail";
import AssignDomainsToUser from "../pages/Users/AssignDomainsToUser";
import DomainsReport from "../pages/NewDomainsRpt";
import MoneyDomainReports from "../pages/Dashboard/MoneyDomainReport";
import BacklinkDetails from "../pages/Backlinks/BacklinkDetails";
import ReviewTask from "../pages/LinkBuilders/ReviewTask";
import ReviewTaskDetail from "../pages/LinkBuilders/ReviewTask/TaskDetail";
import HoldReason from "../pages/HoldReason";
import MoneyDomainReportPage from "../pages/Reports/MoneyDomainReport";
import LinkbuilderPerformanceReport from "../pages/Reports/PerformanceReport";
import BacklinkPerformanceReport from "../pages/Reports/BacklinkPerformanceReport";
import NicheProducts from "../pages/NicheProducts";
import ReviewDailyTasks from "../pages/Reports/ReviewDailyTasks"
import DailyTasks from "../pages/Reports/DailyTasks";
export const routes = [
  {
    path: "/dashboard",
    element: Dashboard,
    permission: "",
    pageTitle: "Dashboard",
  },
  {
    path: "/settings/users",
    element: Users,
    permission: "Users",
    pageTitle: "Users",
  },
  {
    path: "/users/:user",
    element: GroupListHasBatch,
    permission: "",
    pageTitle: "",
  },
  {
    path: "/users/:user/:group",
    element: GroupLinkbuilderBatchList,
    permission: "",
    pageTitle: "",
  },
  {
    path: "/users/:user/:group/:batch",
    element: GroupDomainList,
    permission: "",
    pageTitle: "",
  },
  {
    path: "/users/:user/:group/:batch/:domain",
    element: UpdateUserDomainLinks,
    permission: "Domains",
    pageTitle: "",
  },
  {
    path: "/settings/link-builders",
    element: LinkBuilders,
    permission: "Link Builders Report",
    pageTitle: "LinkBuilders",
  },
  {
    path: "/settings/link-builders/:linkBuilderId",
    element: TaskList,
    permission: "Link Builders Report",
    pageTitle: "TaskList",
  },
  {
    path: "/settings/link-builders/:linkBuilderId/:task",
    element: TaskDetail,
    permission: "Link Builders Report",
    pageTitle: "TaskDetail",
  },
  {
    path: "/reports/link-builders",
    element: LinkBuildersReports,
    permission: "",
    pageTitle: "LinkBuilders Reports",
  },
  {
    path: "/settings/roles",
    element: Roles,
    permission: "Roles",
    pageTitle: "Roles",
  },
  {
    path: "/settings/permissions",
    element: Permissions,
    permission: "Permissions",
    pageTitle: "Permissions",
  },
  {
    path: "/backlinks/backlinks-list",
    element: Backlinks,
    permission: "Backlinks",
    pageTitle: "Backlinks",
  },
  {
    path: "/backlinks/backlinks-type",
    element: BacklinksType,
    permission: "Backlink Type",
    pageTitle: "Backlinks Types",
  },
  {
    path: "/backlinks/content",
    element: BacklinksContent,
    permission: "Backlink Content",
    pageTitle: "Backlinks Content",
  },
  {
    path: "/backlinks/backlink-ready-status",
    element: BacklinkReadyStatus,
    permission: "Backlink Ready Status",
    pageTitle: "Backlink Ready Status",
  },
  {
    path: "/domains",
    element: DomainsReport,
    permission: "Domains",
    pageTitle: "Domains",
  },
  {
    path: "/domains/domain-batches-list",
    element: DomainBatchesList,
    permission: "Domains",
    pageTitle: "Domain Batches List",
  },

  {
    path: "/domains/assign-backlinks",
    element: AssignBacklinks,
    permission: "Backlinks",
    pageTitle: "Assign Backlinks",
  },

  {
    path: "/mailbox",
    element: Mailbox,
    permission: "Mailbox",
    pageTitle: "Mailbox",
  },
  {
    path: "/mailbox/detail",
    element: InboxDetail,
    permission: "Mailbox",
    pageTitle: "",
  },
  {
    path: "/batches",
    element: Batches,
    permission: "Batches",
    pageTitle: "Batches",
  },
  {
    path: "/batches/:batchId",
    element: Batch,
    permission: "Batches",
    pageTitle: "Batches",
  },
  {
    path: "/settings/templates",
    element: Templates,
    permission: "",
    pageTitle: "Templates",
  },
  {
    path: "/task-list",
    element: TaskList,
    permission: "Task List",
    pageTitle: "Task List",
  },
  {
    path: "/task-list/:task",
    element: TaskDetail,
    permission: "Task Detail",
    pageTitle: "Task Detail",
  },
  {
    path: "/assign_domains_to_user",
    element: AssignDomainsToUser,
    permission: "Assign Domains",
    pageTitle: "Assign Domains To User",
  },
  {
    path: "/settings/merchants",
    element: Merchants,
    permission: "Merchants",
    pageTitle: "Merchants",
  },
  {
    path: "/new_batches",
    element: NewBatches,
    permission: "Batches",
    pageTitle: "Batches",
  },
  {
    path: "/reports/money_domain_reports",
    element: MoneyDomainReports,
    permission: "Money Domain Reports",
    pageTitle: "Money Domain Reports",
  },
  {
    path: "/backlink_detail",
    element: BacklinkDetails,
    permission: "Backlinks",
    pageTitle: "Backlinks Detail",
  },
  {
    path: "/reports/review-task",
    element: ReviewTask,
    permission: "Review Tasks",
    pageTitle: "Review Tasks",
  },
  {
    path: "/reports/review-task/:task",
    element: ReviewTaskDetail,
    permission: "Review Tasks",
    pageTitle: "Review Task Detail",
  },
  {
    path: "/settings/hold-reason",
    element: HoldReason,
    permission: "Hold Reason",
    pageTitle: "Hold Reason",
  },
  {
    path: "/reports/money-domain-report",
    element: MoneyDomainReportPage,
    permission: "Money Domain Report",
    pageTitle: "Money Domain Report",
  },
  {
    path: "/reports/performance-report",
    element: LinkbuilderPerformanceReport,
    permission: "Linkbuilders Performance Report",
    pageTitle: "Linkbuilders Performance Report",
  },
  {
    path: "/reports/backlink-performance-report",
    element: BacklinkPerformanceReport,
    permission: "Backlink Performance Report",
    pageTitle: "Backlink Performance Report",
  },
  {
    path: "/settings/niche-products",
    element: NicheProducts,
    permission: "Niche Products",
    pageTitle: "Niche Products",
  },
  {
    path: "/reports/daily-tasks",
    element: DailyTasks,
    permission: "Daily Tasks",
    pageTitle: "Daily Tasks",
  },
  {
    path: "/reports/review-daily-tasks",
    element: ReviewDailyTasks,
    permission: "Review Daily Tasks",
    pageTitle: "Review Daily Tasks",
  },
];

import React, { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DataGrid, ToolBar } from "..";
import { columns } from "./columns";
//import { GridPagination } from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import api from "../../services/api";
import ImportModal from "./ImportModal";
import SourceDomainModal from "./SourceDomainModal";
import userPermission from "../../utils/userPermission";
import Loading from "../../components/Loader/Loading";
import CustomBreadcrumbs from "../common/CustomBreadcrumbs";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Modal } from "../../components";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";
import AlreadyAssignErrorModal from "./AlreadyAssignErrorModal";
import { FormControl } from "@mui/material";
import AutoComplete from "./AutoComplete";
import debounce from "lodash/debounce";
import ImportUpdateExcelModal from "./ImportUpdateExcelModal";
export default function BacklinksTable() {
  // const [backlinkFilters, setBacklinkFilter] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const { selectedUser, module } = location.state || {};
  const [backlinksData, setBacklinksData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [keyToDelete, setKeyToDelete] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  //const [states, setStates] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [selectedData, setSelectedData] = useState([]);
  const [isAlreadyAssignModal, setAlreadyAssignErrorModal] = useState(false);
  const [isExistCobmiErrors, setExistCombiErros] = useState([]);
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "",
    rules: [],
  });
  // Modal
  const [domains, setDomains] = useState([]);
  const [assignedStatus, setAssignedStatus] = useState("");
  const [isSourceDomain, setIsSourceDomain] = useState(true);
  const [toggleStates, setToggleStates] = useState({});
  const handleModal = (data) => {
    setIsSourceDomain(!isSourceDomain);
    setDomains(data);
  };
  const [backlinkCount, setBacklinkCount] = useState({
    totalBacklinks: 0,
    assignedBacklinks: 0,
    unassignedBacklinks: 0,
  });
  const [backlinksTypeList, setBacklinksTypeList] = useState([]);
  const [backlinksStatusList, setBacklinksStatusList] = useState([]);
  const [nicheProductList, setNicheProductList] = useState([]);
  const followStatus = [
    { value: "Nofollow", label: "Nofollow" },
    { value: "Dofollow", label: "Dofollow" },
    { value: "Pending", label: "Pending" },
  ];
  const locationTypes = [
    { value: "country", label: "Country" },
    { value: "state", label: "State" },
    { value: "city", label: "City" },
  ];
  const cancelButtonRef1 = useRef(null);

  const handleAlreadyAssignModal = () => {
    setAlreadyAssignErrorModal(!isAlreadyAssignModal);
  };

  useEffect(() => {
    if (backlinksData && backlinksData.backlinksData) {
      const initialStates = {};
      backlinksData.backlinksData.forEach((item) => {
        let bid = item["b.id"];
        initialStates[bid] = item["b.good_to_assign"] === 1;
      });
      setToggleStates(initialStates);
    }
  }, [backlinksData]);

  const handleToggle = (params) => {
    setToggleStates((prev) => {
      const currentState =
        prev[params?.id] !== undefined
          ? prev[params?.id]
          : backlinksData?.backlinksData?.find((item) => item.id === params?.id)
              ?.b.good_to_assign === 1;

      const newState = !currentState;
      goodToAssignUpdate(
        params?.id,
        newState,
        params?.row["b.backlink_domain"]
      );
      return {
        ...prev,
        [params?.id]: newState,
      };
    });
  };
  const goodToAssignUpdate = async (bid, newState, backlink_domain) => {
    setIsLoading(true);
    try {
      const res = await api.put(`/api/backlinks/updateBacklink`, {
        goodToAssign: newState,
        id: bid,
        backlink_domain: backlink_domain,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Good To Assign Status Changed");
        /* fetchBacklinksData({
          size: paginationModel.pageSize,
          page: paginationModel.page,
          filter: queryOptions,
        }); */
      }
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const handleTaskLink = (backlinkId, task_status, domain) => {
    const stateData = { backlink_id: backlinkId, task_status: task_status, domain };
    const queryString = new URLSearchParams(stateData).toString();
    const baseUrl =
      user?.user?.role_id === 8
        ? `/reports/review-task?${queryString}`
        : `/backlink_detail?${queryString}`;

    window.open(baseUrl, "_blank");
  };

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = backlinksData?.backlinksData?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (data) => {
    return selectedData.some((item) => item["b.id"] === data["b.id"]);
  };
  const onSingleSelect = ({ checked, data }) => {
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            (item) => item["b.id"] !== data["b.id"]
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [isLocation, setLocationdata] = useState([]);
  const [isAutoComLoader, setAutoComLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  /* const initalData = {
    id: "",
    name: "",
  }; */
  const [selectedPayload, setSelectedPayload] = useState({});
  const handleSelect = (item, rowId) => {
    setSelectedPayload({
      name: item?.name,
      id: item?.id,
    });
    setSelectedItem(item);
    const updatedRows = backlinksData?.backlinksData?.map((row) =>
      row["b.id"] === rowId ? { ...row, "b.location_id": item?.id } : row
    );
    if (updatedRows) {
      setBacklinksData({ ...backlinksData, backlinksData: updatedRows });
    }
  };

  const fetchData = useCallback(async (searchQuery, PreLocationType) => {
    setLocationdata([]);
    if (searchQuery?.length === 0) {
      setLocationdata([]);
      return;
    }
    const payload = {
      filters: {
        groupOp: "AND",
        rules: [
          {
            field: "name",
            op: "cn",
            data: searchQuery,
          },
        ],
      },
    };
    setAutoComLoader(true);
    try {
      let newLocationType = PreLocationType;
      let endpoint = "/api/locations";
      if (newLocationType === "country") {
        endpoint += "/countries";
      } else if (newLocationType === "state") {
        endpoint += "/states";
      } else if (newLocationType === "city") {
        endpoint += "/cities ";
      }
      const res = await await api.post(endpoint, payload);
      if (res.status === 200 || res.status === 201) {
        setLocationdata(
          res?.data?.map((item) => ({
            id: item.id,
            name: item.name,
          }))
        );
      }
    } catch (err) {
      console.log(err);
    }
    setAutoComLoader(false);
  }, []);
  const handleQueryChange = useCallback(
    debounce((newQuery, PreLocationType) => {
      fetchData(newQuery, PreLocationType);
    }, 500),
    []
  );

  const resetPayload = () => {
    setSelectedPayload({
      name: "",
      id: "",
    });
  };
  const renderLocationDataEdit = (params) => {
    const { id } = params;
    return (
      <FormControl fullWidth>
        <AutoComplete
          data={isLocation}
          idField="id"
          valueField="name"
          onSelect={handleSelect}
          onQueryChange={handleQueryChange}
          placeholder="Search ..."
          setAutoComLoader={setAutoComLoader}
          isAutoComLoader={isAutoComLoader}
          //selectedItem={selectedPayload?.domain_data}
          selectedItem={selectedPayload}
          extraClass="mt-1"
          rowId={id}
          PreLocationType={params?.row["b.location_type"]}
          setLocationdata={setLocationdata}
          //backlinksData={backlinksData?.backlinksData}
        />
      </FormControl>
    );
  };
  const columnDefs = columns({
    handleModal: handleModal,
    backlinksTypeList: backlinksTypeList,
    backlinksStatusList: backlinksStatusList,
    followStatus: followStatus,
    handleTaskLink: handleTaskLink,
    handleToggle: handleToggle,
    toggleStates: toggleStates,
    backlinksData: backlinksData?.backlinksData,
    selectedData: selectedData,
    onSelectAll: onSelectAll,
    isSelected: isSelected,
    onSingleSelect: onSingleSelect,
    isLoading: isLoading,
    nicheProductList: nicheProductList,
    //renderLocationTypeEdit,
    locationTypes,
    renderLocationDataEdit,
  });
  const [isImport, setIsImport] = useState(true);
  const [isExcelImport, setIsExcelImport] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });

  const getBacklinkStatusList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_status_list`)
      .then((res) => setBacklinksStatusList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  //   Get Type List
  const getBacklinkTypeList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/backlink_type_list`)
      .then((res) => setBacklinksTypeList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const getNicheProductList = () => {
    setIsLoading(true);
    api
      .get(`/api/backlinks/niche_products`)
      .then((res) => setNicheProductList(res.data))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getBacklinkTypeList();
    getBacklinkStatusList();
    getNicheProductList();
  }, []);

  const fetchBacklinksData = async (payload) => {
    payload.user_id = user?.user?.id;
    payload.is_assigned = true;
    payload.assigned_user_id = selectedUser?.id || "";
    setIsLoading(true);
    setBacklinksData([]);
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/`, payload)
      .then((response) => {
        setBacklinksData(response?.data);
        setBacklinkCount(response?.data?.user_backlink_count[0]);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  // const fetchBacklinksFilter = async (payload) => {
  //   setIsLoading(true);
  //   api
  //     .get(
  //       `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/backlinkFilterList`,
  //       payload
  //     )
  //     .then((response) => {
  //       setBacklinkFilter(response.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };
  const fetchStates = async () => {
    setIsLoading(true);
    /*   api
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/location/stateList`)
      .then((response) => {
        setStates(response?.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      }); */
  };
  useEffect(() => {
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter?.filter_type[filter.selectedType],
        filter.selectedValue,
      ]),
    });
    // fetchBacklinksFilter();
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const refreshBacklinksData = () => {
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter?.filter_type[filter.selectedType],
        filter.selectedValue,
      ]),
    });
  };
  /*  const stateOptions = Object.entries(states).map(([value, label]) => ({
    value,
    label,
  })); */
  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.delete(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/${keyToDelete}`
      );
      if (response.status === 200) {
        console.log(
          `Row with ID ${keyToDelete} has been deleted successfully.`
        );
        // Update local state by filtering out the deleted row
        setBacklinksData((prevData) => {
          // Get the updated state data passed to the callback
          const updatedData = prevData.backlinksData?.filter(
            (backlink) => backlink.id !== keyToDelete
          );
          // Return the updated state data to update the local state
          return { ...prevData, backlinksData: updatedData };
        });
        toast.success("Backlink Deleted", { autoClose: 3000 });
        fetchBacklinksData({
          page: paginationModel?.page,
          perPage: paginationModel?.pageSize,
          filter: queryOptions,
        });
      } else {
        toast.error("Backlink couldn't be Deleted", { autoClose: 3000 });
        console.log(`Failed to delete row with ID ${keyToDelete}.`);
      }
    } catch (error) {
      console.error(`Error deleting row with ID ${keyToDelete}: ${error}`);
      toast.error(error.response.data.message);
    }
    setKeyToDelete("");
    handleModal();
  };

  /*   const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    console.log("👊 ~ handlePageChange ~ params:", params);
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    fetchBacklinksData({
      page: +params.page + 1,
      perPage: params.pageSize,
      filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter.filter_type[filter.selectedType],
        filter.selectedValue,
      ]),
    });
  }; */
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  const processRowUpdate = async (newRow, oldRow) => {
    // updated field names
    const updatedRow = newRow;
    const createdRows = []; // Allow adding only one row at a time.
    const updatedRows = [];

    if (updatedRow?.isNew || updatedRow.records?.isNew) {
      createdRows.push({
        ...updatedRow,
        backlink_type_id: updatedRow?.backlink_type_id,
        ready_status_id: updatedRow?.ready_status_id,
        location_type: updatedRow?.location_type,
        location_id: updatedRow?.location_id,
        niche_product_id: updatedRow?.niche_product_id || "",
        isNew: undefined,
        customId: undefined,
      });
    } else {
      updatedRows.push({
        ...updatedRow,
        backlink_type_id: updatedRow?.backlink_type_id,
        ready_status_id: updatedRow?.ready_status_id,
        location_type: updatedRow?.location_type,
        location_id: updatedRow?.location_id,
        niche_product_id: updatedRow?.niche_product_id || "",
      });
    }
    try {
      // update+999999
      if (updatedRows.length) {
        api
          .put(
            `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/updateBacklink`,
            {
              id: updatedRows[0]["b.id"]?.toString(),
              backlink_domain: updatedRows[0]["b.backlink_domain"],
              signup_url: updatedRows[0]["b.signup_url"] || "",
              backlink_location_type:
                updatedRows[0]["b.backlink_location_type"],
              backlink_type_id: updatedRows[0]["b.backlink_type_id"],
              domain_rating: updatedRows[0]["b.domain_rating"],
              ready_status_id: updatedRows[0]["b.ready_status_id"],
              follow_status: updatedRows[0]["b.follow_status"],
              backlink_rank: updatedRows[0]["b.backlink_rank"],
              traffic: updatedRows[0]["b.traffic"],
              niche_product_id: updatedRows[0]["b.niche_product_id"],
              location_type: updatedRows[0]["b.location_type"],
              location_id: updatedRows[0]["b.location_id"],
            }
          )
          .then(() => {
            fetchBacklinksData({
              page: paginationModel?.page,
              perPage: paginationModel?.pageSize,
              filter: queryOptions,
            }); // Fetch updated data from the server and set it in local state
            toast.success("Backlink Updated", { autoClose: 3000 });
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      // create
      if (createdRows.length === 1) {
        api
          .post(
            `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/addBacklink`,
            {
              backlink_domain: createdRows[0]["b.backlink_domain"],
              signup_url: createdRows[0]["b.signup_url"] || "",
              backlink_location_type:
                createdRows[0]["b.backlink_location_type"],
              backlink_type_id: createdRows[0]["b.backlink_type_id"],
              domain_rating: createdRows[0]["b.domain_rating"],
              ready_status_id: createdRows[0]["b.ready_status_id"],
              follow_status: createdRows[0]["b.follow_status"],
              backlink_rank: createdRows[0]["b.backlink_rank"],
              traffic: createdRows[0]["b.traffic"],
              niche_product_id: createdRows[0]["b.niche_product_id"],
              location_type: createdRows[0]["b.location_type"],
              location_id: createdRows[0]["b.location_id"],
            }
          )
          .then(() => {
            fetchBacklinksData({
              page: paginationModel?.page,
              perPage: paginationModel?.pageSize,
              filter: queryOptions,
            });
            toast.success("New Backlink Created!", { autoClose: 3000 });
          })
          .catch((error) => {
            console.log(error);
            toast.error(error.response.data.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
      return updatedRow;
    } catch (e) {
      console.log(e);
    }
  };
  /*   const CustomPagination = (props) => {
    const { paginationProps, api } = props;
    const handleLastPageClick = () => {
      api.setPage(api.state.pagination.pageCount);
    };
    return (
      <GridPagination
        {...paginationProps}
        showLastButton
        showFirstButton
        renderLastButton={(params) => (
          <button onClick={handleLastPageClick} disabled={params.disabled}>
            Last Page
          </button>
        )}
      />
    );
  }; */
  const rows = (backlinksData?.backlinksData || [])?.map((record, index) => {
    const counter = offset + index + 1;
    const records = record;
    const {
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.signup_url": signup_url,
      "b.type": type,
      "b.blacklisted": blacklisted,
      "b.domain_rating": domain_rating,
      "b.robot_group": robot_group,
      "b.ready_status": ready_status,
      "b.follow_status": follow_status,
      "b.traffic": traffic,
      "b.backlink_type_id": backlink_type_id,
      "b.ready_status_id": ready_status_id,
      "bds.total_inReview": total_inReview,
      "bds.total_hold": total_hold,
      "bds.total_completed": total_completed,
      "b.good_to_assign": good_to_assign,
      "b.niche_category": niche_category,
      "b.niche_product_id": niche_product_id,
      "b.location_type": location_type,
      "b.location": location,
      "b.location_id": location_id,
      intersect_count,
    } = record;
    const source_domains_list_count =
      record["bi.source_domains_list"]?.split(",")?.length;
    const created_at = dayjs(record["b.created_at"]).format(
      "ddd, MMM D, YYYY h:mm A"
    );
    const updated_at = dayjs(record["b.updated_at"]).format(
      "ddd, MMM D, YYYY h:mm A"
    );
    return {
      counter,
      records,
      "b.id": id,
      "b.backlink_domain": backlink_domain,
      "b.signup_url": signup_url,
      "b.type": type || "",
      "b.blacklisted": blacklisted,
      "b.domain_rating": domain_rating,
      "b.robot_group": robot_group,
      "b.ready_status": ready_status || "",
      "b.follow_status": follow_status || "",
      "b.traffic": traffic,
      "b.backlink_type_id": backlink_type_id,
      "b.ready_status_id": ready_status_id,
      "bds.total_inReview": total_inReview,
      "bds.total_hold": total_hold,
      "bds.total_completed": total_completed,
      "b.good_to_assign": good_to_assign,
      "b.niche_category": niche_category || "",
      "b.niche_product_id": niche_product_id,
      "b.location_type": location_type,
      "b.location": location,
      "b.location_id": location_id,
      intersect_count,
      "b.created_at": created_at,
      "b.updated_at": updated_at,
      source_domains_list_count,
    };
  });
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    queryOptions.rules.forEach((rule) => {
      if (rule.field === "bds.assigned_users") {
        rule.field = "bds.assigned_users";
        rule.op = rule.op === "Yes" ? "isNotEmpty" : "isEmpty";
      }
    });
    fetchBacklinksData({
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: queryOptions,
      ...(assignedStatus && { type: assignedStatus }),
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      setAssignedStatus("");
      fetchBacklinksData({
        filter: [],
        page: 1,
        perPage: paginationModel.pageSize,
      });
    }
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "isEmpty"
              ? "eq"
              : rule.operator,
          data: rule.value ? rule.value : null,
        },
      ];
    });
    // ?.toLowerCase()?.includes(searchInput?.toLowerCase())
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    "bds.assigned_users": false,
    //"b.backlink_type_id": false,
  });
  const handleColumnVisibilityChange = (model) => {
    setColumnVisibilityModel(model);
  };

  const getAssigned = async (status) => {
    await setAssignedStatus(status ? "assigned" : "notAssigned");
    fetchBacklinksData({
      user_id: user?.user?.id,
      is_assigned: status,
      type: status ? "assigned" : "notAssigned",
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: queryOptions,
    });
  };
  const existingCombinationsModal = (param = null) => {
    setExistCombiErros(param);
    setAlreadyAssignErrorModal(true);
  };
  const assignBacklinks = async (source = null, e, param = null) => {
    if (source) {
      e.preventDefault();
    }
    setIsLoading(true);
    const payload = {
      user_id: selectedUser?.id,
      backlink_ids: selectedData?.map((item) => item["b.id"]),
    };
    if (source && param) {
      payload.skip_existing = param;
    }
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/assign_backlinks_to_user`,
        payload
      )
      .then((res) => {
        setSelectedData([]);
        fetchBacklinksData({
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
        toast.success(res?.data?.message);
        setIsLoading(false);
      })
      .catch((err) => {
        if (err?.response?.data?.existingCombinations) {
          const dataArray = {
            errors: err?.response?.data?.existingCombinations || [],
            username: selectedUser?.username,
            message: err?.response?.data?.message || "",
          };
          existingCombinationsModal(dataArray);
          return;
        }
        toast.error(err.response.data.message);
      });
    if (source) setAlreadyAssignErrorModal(false);
    setIsLoading(false);
  };

  const unassignBacklinks = () => {
    setIsLoading(true);
    const payload = {
      user_id: selectedUser?.id,
      backlink_ids: selectedData?.map((item) => item["b.id"]),
    };
    api
      .post(
        `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/unassign_backlinks_from_user`,
        payload
      )
      .then((res) => {
        setSelectedData([]);
        fetchBacklinksData({
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        });
        toast.success(res?.data?.message);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
    setIsLoading(false);
  };

  const saveBlobAsExcelFile = (blobData) => {
    const downloadLink = window.URL.createObjectURL(new Blob([blobData]));

    const link = document.createElement("a");
    link.href = downloadLink;
    link.setAttribute("download", `backlinks.xlsx`); // Set the filename

    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadLink);
  };
  const downloadCSV = async () => {
    const payload = {
      page: paginationModel?.page,
      perPage: paginationModel?.pageSize,
      filter: selectedFilter?.map((filter) => [
        filter.filter_key,
        filter?.filter_type[filter.selectedType],
        filter.selectedValue,
      ]),
      user_id: user?.user?.id,
      is_assigned: true,
      assigned_user_id: selectedUser?.id || "",
      is_export: true,
    };
    setIsLoading(true);
    api
      .post(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/`, payload, {
        responseType: "blob",
      })
      .then((response) => {
        const blobData = response.data;
        saveBlobAsExcelFile(blobData);
        toast.success("backlinks export successfully");
      })
      .catch((error) => {
        toast.error("Backlinks couldn't be export");
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <div className="px-4 pt-0">
      {isLoading && <Loading />}
      <div className="flex items-center justify-between">
        {!selectedUser ? (
          <CustomBreadcrumbs
            crumbs={[
              { label: "Home", link: "/" },
              { label: "Backlinks", link: "" },
            ]}
          />
        ) : (
          <CustomBreadcrumbs
            crumbs={[
              { label: "Home", link: "/" },
              { label: "Users", link: "/settings/users" },
              {
                label: ` Assign Backlinks to ${
                  selectedUser ? selectedUser?.name : ""
                }`,
                link: "",
              },
            ]}
          />
        )}
        <div className="flex flex-row">
          <button
            className="flex items-center gap-2 btnSecondary mr-1"
            onClick={() => setIsExcelImport(!isExcelImport)}
          >
            Update With Excel
          </button>
          {userPermission("Import Backlinks") ? (
            <button
              className="flex items-center gap-2 btnSecondary mr-1"
              onClick={() => setIsImport(!isImport)}
            >
              Import Backlinks
            </button>
          ) : null}
        </div>
      </div>
      {!isImport && (
        <ImportModal
          isImport={isImport}
          setOpen={setIsImport}
          //stateOptions={stateOptions}
          fetchBacklinksData={fetchBacklinksData}
        />
      )}
      {!isExcelImport && (
        <ImportUpdateExcelModal
          isExcelImport={isExcelImport}
          setOpen={setIsExcelImport}
          fetchBacklinksData={fetchBacklinksData}
        />
      )}

      {!isSourceDomain && (
        <SourceDomainModal
          domains={domains}
          setOpen={setIsSourceDomain}
          open={isSourceDomain}
        />
      )}

      {module && module === "backlink_from_user" ? (
        <div className="flex-cols md:flex flex-wrap justify-start space-x-2 mx-0.5 mt-3">
          <button
            type="button"
            disabled={!selectedData?.length}
            className={`text-sm py-2 px-4 rounded btnTable ${
              !selectedData?.length
                ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                : "bg-white text-black hover:bg-blue-700"
            } `}
            onClick={() => {
              assignBacklinks();
            }}
          >
            <MdAddCircleOutline size="20" className="mr-1" color="#48bb78" />
            ASSIGN BACKLINKS
          </button>
          <button
            type="button"
            disabled={!selectedData?.length}
            className={`text-sm py-2 px-4 rounded btnTable ${
              !selectedData?.length
                ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                : "bg-white text-black hover:bg-blue-700"
            } `}
            onClick={() => {
              unassignBacklinks();
            }}
          >
            <MdRemoveCircleOutline size="20" className="mr-1" color="#48bb78" />
            UNASSIGN BACKLINKS
          </button>

          <button
            type="button"
            className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable text-black ${
              assignedStatus === "assigned"
                ? "border-l-4 border-secondary bg-blue/10"
                : "bg-white"
            }
          `}
            onClick={(e) => getAssigned(true)}
          >
            Assigned
            <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
              {backlinkCount ? backlinkCount?.assignedBacklinks : 0}
            </span>
          </button>
          <button
            type="button"
            className={`text-sm hover:bg-blue-700 py-2 px-4 rounded btnTable  text-black ${
              assignedStatus === "notAssigned"
                ? "border-l-4 border-secondary bg-blue/10"
                : "bg-white"
            }
          `}
            onClick={(e) => getAssigned(false)}
          >
            Not Assigned
            <span className="bg-green-500 text-white px-2 py-1 rounded-md ml-2 text-xs">
              {backlinkCount ? backlinkCount?.unassignedBacklinks : 0}
            </span>
          </button>
        </div>
      ) : null}
      <div className="bg-white my-3 border rounded">
        <DataGrid
          initialState={{ pinnedColumns: { right: ["batch_action"] } }}
          rows={rows}
          columns={columnDefs}
          pinActions={true}
          components={{
            toolbar: ToolBar,
          }}
          componentsProps={{
            toolbar: {
              rowModesModel,
              setBacklinksData,
              setRowModesModel,
              fetchBacklinksData,
              paginationModel,
              setSelectedFilter,
              setPaginationModel,
              rows,
              selectedFilter,
              setIsLoading,
              handleNewFilter,
              backlinksData,
              selectedData,
              assignBacklinks,
              module,
              downloadCSV,
            },
          }}
          editMode="row"
          getRowId={(row) => row["b.id"]}
          rowCount={backlinksData?.totalCount || 0}
          setRowModesModel={setRowModesModel}
          rowModesModel={rowModesModel}
          editable={true}
          pagination="No"
          processRowUpdate={processRowUpdate}
          setBacklinksData={setBacklinksData}
          handleDeleteSubmit={handleDeleteSubmit}
          onFilterModelChange={onFilterChange}
          fetchBacklinksData={refreshBacklinksData}
          columnVisibilityModel={columnVisibilityModel}
          handleColumnVisibilityChange={handleColumnVisibilityChange}
          queryOptions={queryOptions}
          setSelectedItem={setSelectedItem}
          setLocationdata={setLocationdata}
          resetPayload={resetPayload}
        />
      </div>
      <Modal
        open={!isAlreadyAssignModal}
        handleModal={handleAlreadyAssignModal}
        cancelButtonRef={cancelButtonRef1}
        className="max-w-2xl"
        title={
          <span className="text-red-500 text-lg">
            Below backlinks are already assigned to{" "}
            {isExistCobmiErrors?.username}!
          </span>
        }
      >
        <AlreadyAssignErrorModal
          isExistCobmiErrors={isExistCobmiErrors}
          handleBacklinks={assignBacklinks}
          setAlreadyAssignErrorModal={setAlreadyAssignErrorModal}
          setSelectedData={setSelectedData}
        />
      </Modal>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import api from "../../../services/api";
import CustomPagination from "../../../components/common/CustomPagination";
import CustomBreadcrumbs from "../../../components/common/CustomBreadcrumbs";
import TaskDetails from "./TaskDetails";

export default function ReviewDailyTasks() {
  const [tasks, setTasksList] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 1,
    page: 1,
  });

  const [currentTaskId, setCurrentTaskId] = useState(null);

  const fetchTasksList = async () => {
    const url = `/api/report/review_daily_tasks`;
    try {
      const response = await api.post(url);
      if (response.status !== 200) {
        toast.error(response?.data?.message);
        return;
      }
      setTasksList(response.data);
    } catch (error) {
      console.error(error);
      toast.error("Error fetching tasks");
    }
  };

  useEffect(() => {
    fetchTasksList();
  }, []);

  useEffect(() => {
    const currentTaskIndex =
      (paginationModel.page - 1) * paginationModel.pageSize;

    const currentTask = tasks[currentTaskIndex];

    if (currentTask) {
      setCurrentTaskId(currentTask.task_id);
    }
  }, [tasks, paginationModel.page, paginationModel.pageSize]);

  const handlePageChange = (newPageIndex) => {
    const totalPages = Math.ceil(tasks.length / paginationModel.pageSize);

    if (newPageIndex < 1 || newPageIndex > totalPages) {
      toast.error("Please enter a valid page number");
      return;
    }

    setPaginationModel((prevState) => ({
      ...prevState,
      page: newPageIndex,
    }));
  };

  return (
    <div className="px-6 pt-4">
      <CustomBreadcrumbs
        crumbs={[
          { label: "Home", link: "/" },
          { label: "Review Daily Tasks", link: "" },
        ]}
      />
      <ToastContainer />
      {currentTaskId ? (
        <>
          <div className="bg-white my-2 border rounded">
            <div className="flex flex-wrap items-center px-2 py-2">
              <div className="ml-auto mt-2 md:mt-0">
                <CustomPagination
                  currentPage={paginationModel.page}
                  totalPages={Math.ceil(
                    tasks.length / paginationModel.pageSize
                  )}
                  onPageChange={handlePageChange}
                />
              </div>
            </div>
          </div>
          <div className="bg-white my-3 border rounded">
            <TaskDetails currentTaskId={currentTaskId} />
          </div>
        </>
      ) : (
        <div className="bg-white my-2 border rounded">
          <div className="flex items-center px-2 py-2  justify-center">
            <span className="font-medium">No Records..</span>
          </div>
        </div>
      )}
    </div>
  );
}
